// external:
import { useCallback, useEffect, useRef, useState } from "react";
import { mdiFilterRemove, mdiCloseCircleOutline, mdiCheckboxOutline } from "@mdi/js";
import Icon from "@mdi/react";
import classNames from "classnames";
// internal:
import Button from "../Button/Button";
//styling: 
import './MultiSelectInput.scss';

function MultiSelectInputItem(props: {
  item: {
    id: string;
    display: string;
  };
  isSelected?: boolean;
  toggleSelected: (id: string) => void;
}) {
  return (
    <div className={classNames("MultiSelectInput-item", (props.isSelected ? "MultiSelectInput-item-selected" : "MultiSelectInput-item-not-selected"))}  onClick={() => props.toggleSelected(props.item.id)}>
      <div>
        {props.isSelected ? <Icon path={mdiCheckboxOutline} size={0.75}/> : <></>}
      </div>
      <div>
        {props.item.display}
      </div>
    </div>
  )
}

export default function MultiSelectInput(props: {
  items: { id: string, display: string }[];
  onSelectionChange?: (selected: string[]) => void;
  description?: string;
}) {
  const [currentlySelected, setCurrentlySelected] = useState<string[]>([]);
  const [isInputInFocus, setIsInputInFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchString, setSearchString] = useState("");
  const [itemsDraw, setItemsDraw] = useState<any[]>([]);

  const toggleSelectedItem = useCallback((id: string) => {
    const index = currentlySelected.indexOf(id);
    if (index >= 0) {
      const before = currentlySelected.slice(0, index);
      if (index < currentlySelected.length) {
        setCurrentlySelected([...before, ...currentlySelected.slice(index + 1, currentlySelected.length)]);
      } else {
        setCurrentlySelected([...before]);
      }
    } else {
      setCurrentlySelected([...currentlySelected, id]);
    }
  }, [currentlySelected])

  const onInput = (e: any) => {
    setSearchString(e.target.value);
  };

  const onSelectedClicked = (e: any) => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (props.onSelectionChange) {
      props.onSelectionChange(currentlySelected);
    }
  }, [currentlySelected, props]);

  useEffect(() => {
    setItemsDraw(props.items.map((item: { id: string, display: string }) => {


      return (item.display.match(RegExp(searchString, "i")) || currentlySelected.includes(item.id)) ? <MultiSelectInputItem key={item.id} item={item} isSelected={currentlySelected.includes(item.id)} toggleSelected={toggleSelectedItem} /> : <></>
    }));
  }, [currentlySelected, searchString, props.items, toggleSelectedItem]);

  const selectedDraw = currentlySelected.map((id: string, index: number) =>
    <span key={id}>{index > 0 ? ", " : ""} {props.items.find((item: { id: string, display: any }) => item.id === id)?.display}</span>
  );

  const clearFilter = () => {
    setCurrentlySelected([]);
  };

  const clearSearchString = () => {
    setSearchString("");
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }

  return (
    <div className="MultiSelectInput">
      <div className={classNames("MultiSelectInput-selected", (currentlySelected.length > 0 ? "MultiSelectInput-filtering" : ""))} onClick={onSelectedClicked}>{props.description} <strong>{selectedDraw}</strong></div>
      <div className={classNames("MultiSelectInput-items",  (isInputInFocus ? "MultiSelectInput-items-show" : ""))}>
        <div>
          <input type="text" className="MultiSelectInput-items-input" value={searchString} ref={inputRef} onFocus={() => setIsInputInFocus(true)} onBlur={() => setIsInputInFocus(false)} onInput={onInput} placeholder="Search" />
          {searchString !== "" ? <span  className="MultiSelectInput-items-input-clear" onClick={clearSearchString}><Icon color="gray" path={mdiCloseCircleOutline} size={0.9}/></span> : <></>}
          <Button text="Clear filter" icon={mdiFilterRemove} onClick={clearFilter} />
        </div>
        <div className="MultiSelectInput-items-list">
          {itemsDraw}
        </div>

      </div>
    </div>
  );
}