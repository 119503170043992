// external:
import { useState, useEffect } from "react";
import { Firestore } from "firebase/firestore";
import ReactTooltip from 'react-tooltip';
// internal:
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import { RootState } from "../../../../../Redux/store";
import { addElement, addElementWithLink, syncWithServer } from "../../../../../Redux/FactorySlice";
import { FactoryConnection, Socket } from "../../../../../Model/FactoryConnection";
import FactoryElement from "../../../../../Model/FactoryElement";
import { FactoryElementParameters } from '../../../../../Model/FactoryElement';
import { clearContextMenuData, ContextMenuData, ContextMenuOperation } from "../../../../../Components/ContextMenu/ContextMenu";
import ConveyorTooltip from "./Tooltips/ConveyorTooltip";
import SimpleTooltip from "./Tooltips/SimpleTooltip";
import SocketInputTooltip from "./Tooltips/SocketInputTooltip";
import SocketOutputTooltip from "./Tooltips/SocketOutputTooltip";
import PipelineTooltip from "./Tooltips/PipelineTooltip";
import { MouseContext } from "./MouseContext";
import ConnectionAddingHelper from "./ConnectionAddingHelper/ConnectionAddingHelper";
import { elementIoRegEx } from "./Helpers";
import LoadingSpinner from "../../../../../Components/LoadingSpinner/LoadingSpinner";
import SvgCanvas from "./SvgCanvas/SvgCanvas";
import FactoryElementDraw from "./FactoryElementDraw/FactoryElementDraw";
import Connection from "./Connection/Connection";
import { Recipe } from "../../../../../Model/Recipe";
import { ItemAmount } from "../../../../../Model/Item";
//styling:
import './FactoryCanvas.scss';
import AtlasImage from "../../../../../Components/ItemImage/ItemImage";
import Icon from "@mdi/react";
import { mdiArrowRight } from "@mdi/js";

function AddElementContextMenuDisplay(props: {recipe: Recipe}) {
  const ingredientsDraw = props.recipe.ingredients.map((ia: ItemAmount) => <AtlasImage size={23} name={ia.item}/>)
  const productsDraw = props.recipe.products.map((ia: ItemAmount) => <AtlasImage size={23} name={ia.item}/>)

  return (
    <span>
      <div>
        Add <strong>{props.recipe.name}</strong>
      </div>
      <div className="AddElementContextMenuDisplay-info">
        <AtlasImage size={23} name={props.recipe.executor}/>
        <div className="AddElementContextMenuDisplay-items">
          {ingredientsDraw} <Icon path={mdiArrowRight} size={0.8}/> {productsDraw}
        </div>
      </div>
    </span>
  );
}

function addRelatedElementsContextMenu(pageX: number, pageY: number, recipes: Recipe[], type: string, item: string, dispatch: any, firestore: any, svgPos: {x: number, y: number}, socket: Socket) : ContextMenuData {
  const ingredientsFilter: string[] = [];
  const productsFilter: string[] = [];

  if (type === "O") {
    ingredientsFilter.push(item);
  } else {
    productsFilter.push(item);
  }

  const filterFunction = (r: Recipe): boolean => {
    return ((ingredientsFilter.length === 0) || (r.ingredients.map((ia: ItemAmount) => ingredientsFilter.includes(ia.item)).includes(true))) &&
      ((productsFilter.length === 0) || (r.products.map((ia: ItemAmount) => productsFilter.includes(ia.item)).includes(true)))
  };

  const filteredRecipes = recipes.filter((r: Recipe) => filterFunction(r));

  const operations: ContextMenuOperation[] = [];

  if (filteredRecipes.length === 0) {
    return {
      x: pageX,
      y: pageY,
      isVisible: false,
      operations: operations,
    };
  } else {
    filteredRecipes.forEach((r: Recipe) => {
      operations.push({
        name: r.name,
        display: <AddElementContextMenuDisplay key={r.name} recipe={r}/>,
        onclick: () => {
          const el: FactoryElementParameters = {
            recipeId: r.id,
            position: {x: svgPos.x, y: svgPos.y},
            number: 1
          };
    
          dispatch(syncWithServer(addElementWithLink(el, socket), firestore));
        }
      });
    })
  
    return {
      x: pageX,
      y: pageY,
      isVisible: true,
      operations: operations,
    };
  }
}

function FactoryCanvas(props: {
  firestore: Firestore;
  contextMenu: (context: ContextMenuData) => void;
}) {
  const [mouseDragOffset, setMouseDragOffset] = useState({x: 0, y: 0});
  const [draggedId, setDraggedId] = useState<string | null>(null);
  const [mouseOverId, setMouseOverId] = useState<string | null>(null);

  const dispatch = useAppDispatch();

  const elements = useAppSelector((state: RootState) => state.factory.elements);
  const connections = useAppSelector((state: RootState) => state.factory.connections);
  const isLoaded = useAppSelector((state: RootState) => state.factory.id !== "");
  const isEmpty = useAppSelector((state: RootState) => state.factory.elements.length === 0);
  const recipes = useAppSelector((state: RootState) => state.factory.recipes);

  const elementsDraw = elements.map((fe: FactoryElement) => {
    if (fe.id !== draggedId) {
      return (
        <FactoryElementDraw key={fe.id} fe={fe}
          positionOffset={{x: 0, y: 0}}
          setDragged={setDraggedId}
          setMouseOver={setMouseOverId}
          firestore={props.firestore}
          contextMenu={props.contextMenu}
        />
        );
    } else {
      return <></>;
    }
  });

  const draggedElement = elements.find((fe: FactoryElement) => fe.id === draggedId);
  const draggedElementDraw = draggedElement ? 
    <FactoryElementDraw key={draggedId+"dragged"} fe={draggedElement}
      positionOffset={mouseDragOffset}
      setDragged={setDraggedId}
      setMouseOver={setMouseOverId}
      isGrabbed
      firestore={props.firestore}
      contextMenu={props.contextMenu}
    /> : <></>;

  let helper: any = null;
  if(draggedId) {
    const draggedIdMatchIo = draggedId.match(elementIoRegEx);
    if(draggedIdMatchIo && draggedIdMatchIo.groups && draggedIdMatchIo.groups.element && draggedIdMatchIo.groups.type && draggedIdMatchIo.groups.conn) {
      helper = <ConnectionAddingHelper
          connectedElement={draggedIdMatchIo.groups.element}
          connectionNumber={Number(draggedIdMatchIo.groups.conn)}
          offset={mouseDragOffset}
          connectedType={draggedIdMatchIo.groups.type}
          connectionId = {draggedId}
        />
    }
  }

  const connectionsDraw = connections.map((fc: FactoryConnection) => {
    if (fc.end.element === draggedId)
      return <Connection key={fc.start.element+" "+fc.end.element} fc={fc} endOffset={mouseDragOffset} firestore={props.firestore} contextMenu={props.contextMenu}/>
    if (fc.start.element === draggedId)
      return <Connection key={fc.start.element+" "+fc.end.element} fc={fc} startOffset={mouseDragOffset} firestore={props.firestore} contextMenu={props.contextMenu}/>
    else
      return <Connection key={fc.start.element+" "+fc.end.element} fc={fc} firestore={props.firestore} contextMenu={props.contextMenu}/>
  });

  function clearDragOffset() {
    setMouseDragOffset({x: 0, y: 0})
  }

  function addToDragOffset(delta: {x: number, y: number}) {
    setMouseDragOffset(previous => {return {x: previous.x + delta.x, y: previous.y + delta.y};})
  }

  function svgOnMouseDown (e: any) {
    props.contextMenu(clearContextMenuData());
  }

  const svgOnMouseUp = (e: any, svgPos: {x: number, y: number}) => {
    if (mouseOverId === null && draggedId !== null) {
      const draggedIdMatchIo = draggedId.match(elementIoRegEx);
      if (draggedIdMatchIo && draggedIdMatchIo.groups && draggedIdMatchIo.groups.type) {
        const s: Socket = {
            element: draggedIdMatchIo.groups.element,
            type: draggedIdMatchIo.groups.type,
            socket: Number(draggedIdMatchIo.groups.conn),
          };
        props.contextMenu(addRelatedElementsContextMenu(e.pageX, e.pageY, recipes, draggedIdMatchIo.groups.type, draggedIdMatchIo.groups.item, dispatch, props.firestore, svgPos, s));
      }
    }
    setDraggedId(null);
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const svgOnDrop = (e: any, svgPos: {x: number, y: number}) => {
    var data = e.dataTransfer.getData('factory/recipe');
    if (data) {
      const el: FactoryElementParameters = {
        recipeId: data,
        position: {x: svgPos.x, y: svgPos.y},
        number: 1
      };

      dispatch(syncWithServer(addElement(el), props.firestore));
    }
  };

  const svgOnDragEnter = (e: any) => {
    var items = e.dataTransfer.items;
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      if (item.kind === 'string' && item.type === 'factory/recipe') {
        e.preventDefault();
        return;
      }
    }
  };

  const svgOnDragOver = (e: any) =>  {
    e.dataTransfer.dropEffect = 'move';
    e.preventDefault();
  };

  return (
    <div className="FactoryCanvas">
      {isLoaded ? "" : <LoadingSpinner/>}
      <MouseContext.Provider value={{draggedObject: draggedId, mouseOver: mouseOverId}}>
        <SvgCanvas
          svgOnMouseDown={svgOnMouseDown}
          svgOnMouseUp={svgOnMouseUp}
          clearDragOffset={clearDragOffset}
          addToDragOffset={addToDragOffset}
          onDrop={svgOnDrop}
          onDragEnter={svgOnDragEnter}
          onDragOver={svgOnDragOver}
        >
          {elementsDraw}
          {draggedElementDraw}
          {connectionsDraw}
          {helper}
        </SvgCanvas>
        {isEmpty && isLoaded ? <div className="FactoryCanvas-tutorial">Drop elements from Recipes Browser below to the Factory Canvas.</div> : <></>}
        <SocketInputTooltip/>
        <SocketOutputTooltip/>
        <SimpleTooltip/>
        <ConveyorTooltip/>
        <PipelineTooltip/>
      </MouseContext.Provider>
    </div>
  );
}

export default FactoryCanvas;