import NavBarMenuItem from "../NavBar/NavBarMenuItem"
import NavBarCheckboxItem from "../NavBar/NavBarCheckboxItem"
import { useAppDispatch, useAppSelector } from "../../Redux/hooks"
import { RootState } from "../../Redux/store"
import { setEditorAssetBrowserSettings, setEditorConnectionsSettings } from "../../Redux/SettingsSlice"

export default function ViewMenu(){
  const isThroughputVisible = useAppSelector((state: RootState) => state.settings.editor.connections.isThroughputVisible);
  const isPerMinuteModeEnabled = useAppSelector((state: RootState) => state.settings.editor.assetBrowser.isPerMinuteModeEnabled);
  const dispatch = useAppDispatch();

  const toggleThroughput = () => {
    dispatch(setEditorConnectionsSettings({isThroughputVisible: !isThroughputVisible}));
  }

  const togglePerMinuteMode = () => {
    dispatch(setEditorAssetBrowserSettings({isPerMinuteModeEnabled: !isPerMinuteModeEnabled}))
  }

  return (
    <NavBarMenuItem display="View">
      <NavBarCheckboxItem display="Show throughput" value={isThroughputVisible} action={toggleThroughput}/>
      <NavBarCheckboxItem display="Show Recipes throughput per minute" value={isPerMinuteModeEnabled} action={togglePerMinuteMode}/>
    </NavBarMenuItem>
  )
}