// external:
import { Auth } from 'firebase/auth';
import { Firestore } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link } from 'react-router-dom';
import { mdiPlus, mdiUpload, mdiDeveloperBoard, mdiReddit } from '@mdi/js';
import { useFilePicker } from 'use-file-picker';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
// internal:
import FactoriesList from '../FactoryPlanner/FactoriesList';
import Button from '../../Components/Button/Button';
import { loadFactoryFromSave } from '../../Redux/FactorySlice';
import { useAppDispatch } from "../../Redux/hooks";
import WelcomeMessage from './WelcomeMessage';
// styling:
import './WelcomePage.scss';
import NavBarContent from '../../Components/NavBar/NavBarContent';

export default function WelcomePage(props: {
  firestore: Firestore,
  auth: Auth,
  showPrompt: (title: string, content: string, onAccepted: () => void) => void;
}) {
  const [user] = useAuthState(props.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [openFileSelector, { filesContent }] = useFilePicker({
    accept: '.json',
    multiple: false
  });

  useEffect(() => {
    filesContent.forEach((file: any) => {
      if (file.name !== "") {
        const promise = dispatch(loadFactoryFromSave(JSON.parse(file.content), "file", file.name));
        promise.finally(() => {
          navigate("/factory/file");
        });
      }
    });
  }, [filesContent, dispatch, navigate]);

  return(
    <div className="WelcomePage">
      <NavBarContent className="WelcomePage-header">
        <div className="WelcomePage-header-buttons">
            <Link to="/factory/new">
              <Button className="Create" icon={mdiPlus} text="Create Factory"/>
            </Link>
            <Button icon={mdiUpload} text="Open Factory from file" onClick={openFileSelector}/>
          </div>
      </NavBarContent>
      {user ? <FactoriesList firestore={props.firestore} user={user} showPrompt={props.showPrompt}/> : <WelcomeMessage/>}
      <div className="WelcomePage-development">
        <p>
        This app is under development. If you want to see current or get in touch head down here:</p>
        <a href="https://github.com/warjoy-k/factory-modeler-issues/projects/1">
          <Button className="Development" icon={mdiDeveloperBoard} text="Development backlog"/>
        </a>
        <a href="https://www.reddit.com/r/cogler/">
          <Button className="Development" icon={mdiReddit} text="Reddit"/>
        </a>
      </div>
    </div>
  );
}
