import { configureStore } from '@reduxjs/toolkit';
import factoryReducer from './FactorySlice';
import factoriesReducer from './FactoriesSlice';
import settingsReducer from './SettingsSlice';

const store = configureStore({
  reducer: {
    factory: factoryReducer,
    factories: factoriesReducer,
    settings: settingsReducer,
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;