import './EditorUI.scss';
import EditorComponentFactory from "./EditorComponentFactory/EditorComponentFactory";
import { SectionConfig , ElementConfig } from './EditorComponentFactory/EditorComponentFactory';
import { EditorContext } from './EditorContext/EditorContext';
import { useEffect, useState } from 'react';

export interface EditorPrimitiveDefinition {
  id: string;
  name: string;
  element: any;
}
export const testPrimitives =  [
    {
      id: "C1_A", 
      name: "C1 A Section",
      element: <div>SEKCJA C1 A</div>},
    {
      id: "C1_B", 
      name: "C1 B Section",
      element: <div>SEKCJA C1 B</div>},
    {
      id: "C2_A", 
      name: "C2 A Section",
      element: <div>SEKCJA C2 A</div>},
    {
      id: "C2_B", 
      name: "C2 B Section",
      element: <div>SEKCJA C2 B</div>},
    {
      id: "C2_C",
      name: "C2 C Section",
      element: <div>SEKCJA C2 C</div>},
    {
      id: "C2_D",
      name: "C2 D Section",
      element: <div>SEKCJA C2 D</div>},
    {
      id: "C2_E",
      name: "C2 E Section",
      element: <div>SEKCJA C2 E</div>},
    {
      id: "C3",
      name: "C3 Section",
      element: <div>SEKCJA C3</div>},
  ];

export const testSections: SectionConfig[] = [
  {
    id:"C1",
    type:"vertical",
    elements: [
      {
        id: "C1_A",
        weight: 0.1
      }, {
        id: "C1_B",
        weight: 0.9
      }
    ]
  }, {
    id: "C2",
    type:"cards",
    elements: [
      {
        id: "C2_A", 
        weight: 0.1
      }, {
        id: "C2_B", 
        weight: 0.4
      }, {
        id: "C2_C", 
        weight: 0.1
      }, {
        id: "C2_D", 
        weight: 0.2
      }, {
        id: "C2_E", 
        weight: 0.2
      }
    ]
  }, {
    id: "root",
    type: "horizontal",
    elements: [
      {
        id: "C1", 
        weight: 0.2
      }, {
        id: "C2", 
        weight: 0.6
      }, {
        id: "C3", 
        weight: 0.2
      }
    ]
  }
];

export default function EditorUI(props: {
  primitives: EditorPrimitiveDefinition[];
  initialSections: SectionConfig[];
}) {
  let [sections, setSections] = useState<SectionConfig[]>(props.initialSections);
  
  useEffect(() => {
    setSections([...props.initialSections])
  }, [props.initialSections]);

  function sectionsConfigProvider(): SectionConfig[] {
    return sections;
  }
  
  function updateSectionElement(sectionId: string, id: string, size: number) {
    const section = sections.find((value: SectionConfig) => value.id === sectionId);
    const element = section?.elements.find((value: ElementConfig) => value.id === id);
    if(element)
      element.weight = size;
    setSections([...sections]);
  }

  return (
    <EditorContext.Provider value={{primitives: () => {return props.primitives;}, sections: sectionsConfigProvider, updateSectionElement: updateSectionElement}}>
      <EditorComponentFactory id="root" sections={sections}/>
    </EditorContext.Provider>
  );
}