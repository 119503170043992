// external:
import { useNavigate } from 'react-router-dom';
import { mdiFileEdit, mdiDelete } from '@mdi/js';
import { Firestore } from 'firebase/firestore';
// internal:
import { FactoryListItem } from '../../Redux/FactoriesSlice';
import Button from '../../Components/Button/Button';
import { useAppDispatch } from '../../Redux/hooks';
import { loadFactoryFromSave } from '../../Redux/FactorySlice';
// styling
import './FactoryListElement.scss';

function FactoryListElement(props: {
  factory: FactoryListItem;
  deleteFactory: (id: any) => void;
  firestore: Firestore;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const openFactory = async () => {
    const promise = dispatch(loadFactoryFromSave(props.factory, "cloud", props.factory.id))
    promise.finally(() => {
      navigate("/factory/"+props.factory.id);
    });
  };

  return (
    <div key={props.factory.id} className="FactoryListElement">
      <div className="FactoryListElement-name">
        {props.factory.name}
      </div>
      <div className="FactoryListElement-template">
        {props.factory.template}
      </div>
      <div className="FactoryListElement-buttons">
        <Button text="Open" icon={mdiFileEdit} onClick={openFactory}/>
        <Button onClick={() => props.deleteFactory(props.factory.id)} className="Delete" text="Delete" icon={mdiDelete}/>
      </div>
    </div>
  );
}

export default FactoryListElement;