import React from "react";
import { SectionConfig } from "../EditorComponentFactory/EditorComponentFactory";
import { EditorPrimitiveDefinition } from "../EditorUI";

export const EditorContext = React.createContext<{
  primitives: () => EditorPrimitiveDefinition[],
  sections: () => SectionConfig[],
  updateSectionElement: (sectionId: string, id: string, size: number) => void;
}>({
  primitives: () => [],
  sections: () => [],
  updateSectionElement: (sectionId: string, id: string, size: number) => {},
});