import Converter from "../Converter";
import { FactoryElementParams } from "../Versions/V1";
import SaveFormatV2 from "../Versions/V2";
import SaveFormatV3 from "../Versions/V3";

const recipesTransitionTable = [
  { id: "aluminum-casing", name: "Aluminum Casing" },
  { id: "actual-snow", name: "Actual Snow" },
  { id: "biocoal", name: "Biocoal" },
  { id: "biomass-alien-carapace", name: "Biomass (Alien Carapace)" },
  { id: "biomass-alien-organs", name: "Biomass (Alien Organs)" },
  { id: "biomass-leaves", name: "Biomass (Leaves)" },
  { id: "biomass-mycelia", name: "Biomass (Mycelia)" },
  { id: "biomass-wood", name: "Biomass (Wood)" },
  { id: "cable", name: "Cable" },
  { id: "candy-cane", name: "Candy Cane" },
  { id: "cast-screw", name: "Cast Screw" },
  { id: "caterium-wire", name: "Caterium Wire" },
  { id: "charcoal", name: "Charcoal" },
  { id: "color-cartridge", name: "Color Cartridge" },
  { id: "concrete", name: "Concrete" },
  { id: "copper-powder", name: "Copper Powder" },
  { id: "copper-sheet", name: "Copper Sheet" },
  { id: "empty-canister", name: "Empty Canister" },
  { id: "empty-fluid-tank", name: "Empty Fluid Tank" },
  { id: "ficsmas-bow", name: "FICSMAS Bow" },
  { id: "ficsmas-tree-branch", name: "FICSMAS Tree Branch" },
  { id: "iron-plate", name: "Iron Plate" },
  { id: "iron-rod", name: "Iron Rod" },
  { id: "iron-wire", name: "Iron Wire" },
  { id: "power-shard-1", name: "Power Shard (1)" },
  { id: "power-shard-2", name: "Power Shard (2)" },
  { id: "power-shard-5", name: "Power Shard (5)" },
  { id: "quartz-crystal", name: "Quartz Crystal" },
  { id: "quickwire", name: "Quickwire" },
  { id: "screw", name: "Screw" },
  { id: "silica", name: "Silica" },
  { id: "snowball", name: "Snowball" },
  { id: "solid-biofuel", name: "Solid Biofuel" },
  { id: "spiked-rebar", name: "Spiked Rebar" },
  { id: "steel-beam", name: "Steel Beam" },
  { id: "steel-canister", name: "Steel Canister" },
  { id: "steel-pipe", name: "Steel Pipe" },
  { id: "steel-rod", name: "Steel Rod" },
  { id: "steel-screw", name: "Steel Screw" },
  { id: "wire", name: "Wire" },
  { id: "adhered-iron-plate", name: "Adhered Iron Plate" },
  { id: "ai-limiter", name: "AI Limiter" },
  { id: "alclad-aluminum-sheet", name: "Alclad Aluminum Sheet" },
  { id: "alternate-alclad-casing", name: "Alternate: Alclad Casing" },
  { id: "assembly-director-system", name: "Assembly Director System" },
  { id: "automated-wiring", name: "Automated Wiring" },
  { id: "black-powder", name: "Black Powder" },
  { id: "bolted-frame", name: "Bolted Frame" },
  { id: "bolted-iron-plate", name: "Bolted Iron Plate" },
  { id: "caterium-circuit-board", name: "Caterium Circuit Board" },
  { id: "cheap-silica", name: "Cheap Silica" },
  { id: "circuit-board", name: "Circuit Board" },
  { id: "coated-iron-canister", name: "Coated Iron Canister" },
  { id: "coated-iron-plate", name: "Coated Iron Plate" },
  { id: "compacted-coal", name: "Compacted Coal" },
  { id: "copper-rotor", name: "Copper Rotor" },
  { id: "crystal-computer", name: "Crystal Computer" },
  { id: "electric-motor", name: "Electric Motor" },
  { id: "electrode-circuit-board", name: "Electrode Circuit Board" },
  { id: "electromagnetic-connection-rod", name: "Electromagnetic Connection Rod" },
  { id: "rlectromagnetic-control-rod", name: "Electromagnetic Control Rod" },
  { id: "encased-industrial-beam", name: "Encased Industrial Beam" },
  { id: "encased-industrial-pipe", name: "Encased Industrial Pipe" },
  { id: "encased-plutonium-cell", name: "Encased Plutonium Cell" },
  { id: "fabric", name: "Fabric" },
  { id: "ficsmas-decoration", name: "FICSMAS Decoration" },
  { id: "ficsmas-ornament-bundle", name: "FICSMAS Ornament Bundle" },
  { id: "ficsmas-wonder-star", name: "FICSMAS Wonder Star" },
  { id: "fine-black-powder", name: "Fine Black Powder" },
  { id: "fine-concrete", name: "Fine Concrete" },
  { id: "fused-quickwire", name: "Fused Quickwire" },
  { id: "fused-wire", name: "Fused Wire" },
  { id: "heat-exchanger", name: "Heat Exchanger" },
  { id: "heat-sink", name: "Heat Sink" },
  { id: "insulated-cable", name: "Insulated Cable" },
  { id: "modular-frame", name: "Modular Frame" },
  { id: "motor", name: "Motor" },
  { id: "nobelisk", name: "Nobelisk" },
  { id: "oc-supercomputer", name: "OC Supercomputer" },
  { id: "plutonium-fuel-unit", name: "Plutonium Fuel Unit" },
  { id: "pressure-conversion-cube", name: "Pressure Conversion Cube" },
  { id: "quickwire-cable", name: "Quickwire Cable" },
  { id: "quickwire-stator", name: "Quickwire Stator" },
  { id: "reinforced-iron-plate", name: "Reinforced Iron Plate" },
  { id: "rotor", name: "Rotor" },
  { id: "rubber-concrete", name: "Rubber Concrete" },
  { id: "silicon-circuit-board", name: "Silicon Circuit Board" },
  { id: "smart-plating", name: "Smart Plating" },
  { id: "stator", name: "Stator" },
  { id: "steel-coated-plate", name: "Steel Coated Plate" },
  { id: "steel-rotor", name: "Steel Rotor" },
  { id: "steeled-frame", name: "Steeled Frame" },
  { id: "stitched-iron-plate", name: "Stitched Iron Plate" },
  { id: "versatile-framework", name: "Versatile Framework" },
  { id: "battery", name: "Battery" },
  { id: "cooling-device", name: "Cooling Device" },
  { id: "cooling-system", name: "Cooling System" },
  { id: "diluted-fuel", name: "Diluted Fuel" },
  { id: "encased-uranium-cell", name: "Encased Uranium Cell" },
  { id: "fertile-uranium", name: "Fertile Uranium" },
  { id: "fused-modular-frame", name: "Fused Modular Frame" },
  { id: "heat-fused-frame", name: "Heat-Fused Frame" },
  { id: "instant-scrap", name: "Instant Scrap" },
  { id: "nitric-acid", name: "Nitric Acid" },
  { id: "non-fissile-uranium", name: "Non-fissile Uranium" },
  { id: "turbo-blend-fuel", name: "Turbo Blend Fuel" },
  { id: "aluminum-ingot", name: "Aluminum Ingot" },
  { id: "coke-steel-ingot", name: "Coke Steel Ingot" },
  { id: "compacted-steel-ingot", name: "Compacted Steel Ingot" },
  { id: "copper-alloy-ingot", name: "Copper Alloy Ingot" },
  { id: "iron-alloy-ingot", name: "Iron Alloy Ingot" },
  { id: "solid-steel-ingot", name: "Solid Steel Ingot" },
  { id: "steel-ingot", name: "Steel Ingot" },
  { id: "instant-plutonium-cell", name: "Instant Plutonium Cell" },
  { id: "nuclear-pasta", name: "Nuclear Pasta" },
  { id: "plutonium-pellet", name: "Plutonium Pellet" },
  { id: "packaged-alumina-solution", name: "Packaged Alumina Solution" },
  { id: "packaged-fuel", name: "Packaged Fuel" },
  { id: "packaged-heavy-oil-residue", name: "Packaged Heavy Oil Residue" },
  { id: "packaged-liquid-biofuel", name: "Packaged Liquid Biofuel" },
  { id: "packaged-nitric-acid", name: "Packaged Nitric Acid" },
  { id: "packaged-nitrogen-gas", name: "Packaged Nitrogen Gas" },
  { id: "packaged-oil", name: "Packaged Oil" },
  { id: "packaged-sulfuric-acid", name: "Packaged Sulfuric Acid" },
  { id: "packaged-turbofuel", name: "Packaged Turbofuel" },
  { id: "packaged-water", name: "Packaged Water" },
  { id: "unpackage-alumina-solution", name: "Unpackage Alumina Solution" },
  { id: "unpackage-fuel", name: "Unpackage Fuel" },
  { id: "unpackage-heavy-oil-residue", name: "Unpackage Heavy Oil Residue" },
  { id: "unpackage-liquid-biofuel", name: "Unpackage Liquid Biofuel" },
  { id: "unpackage-nitric-acid", name: "Unpackage Nitric Acid" },
  { id: "unpackage-nitrogen-gas", name: "Unpackage Nitrogen Gas" },
  { id: "unpackage-oil", name: "Unpackage Oil" },
  { id: "unpackage-sulfuric-acid", name: "Unpackage Sulfuric Acid" },
  { id: "unpackage-turbofuel", name: "Unpackage Turbofuel" },
  { id: "unpackage-water", name: "Unpackage Water" },
  { id: "alumina-solution", name: "Alumina Solution" },
  { id: "aluminum-scrap", name: "Aluminum Scrap" },
  { id: "coated-cable", name: "Coated Cable" },
  { id: "diluted-packaged-fuel", name: "Diluted Packaged Fuel" },
  { id: "electrode-aluminum-scrap", name: "Electrode - Aluminum Scrap" },
  { id: "fuel", name: "Fuel" },
  { id: "heavy-oil-residue", name: "Heavy Oil Residue" },
  { id: "liquid-biofuel", name: "Liquid Biofuel" },
  { id: "petroleum-coke", name: "Petroleum Coke" },
  { id: "plastic", name: "Plastic" },
  { id: "polyester-fabric", name: "Polyester Fabric" },
  { id: "polymer-resin", name: "Polymer Resin" },
  { id: "pure-caterium-ingot", name: "Pure Caterium Ingot" },
  { id: "pure-copper-ingot", name: "Pure Copper Ingot" },
  { id: "pure-iron-ingot", name: "Pure Iron Ingot" },
  { id: "pure-quartz-crystal", name: "Pure Quartz Crystal" },
  { id: "recycled-plastic", name: "Recycled Plastic" },
  { id: "recycled-rubber", name: "Recycled Rubber" },
  { id: "residual-fuel", name: "Residual Fuel" },
  { id: "residual-plastic", name: "Residual Plastic" },
  { id: "residual-rubber", name: "Residual Rubber" },
  { id: "rubber", name: "Rubber" },
  { id: "sloppy-alumina", name: "Sloppy Alumina" },
  { id: "steamed-copper-sheet", name: "Steamed Copper Sheet" },
  { id: "sulfuric-acid", name: "Sulfuric Acid" },
  { id: "turbo-heavy-fuel", name: "Turbo Heavy Fuel" },
  { id: "turbofuel", name: "Turbofuel" },
  { id: "wet-concrete", name: "Wet Concrete" },
  { id: "adaptive-control-unit", name: "Adaptive Control Unit" },
  { id: "automated-miner", name: "Automated Miner" },
  { id: "automated-speed-wiring", name: "Automated Speed Wiring" },
  { id: "beacon", name: "Beacon" },
  { id: "caterium-computer", name: "Caterium Computer" },
  { id: "classic-battery", name: "Classic Battery" },
  { id: "computer", name: "Computer" },
  { id: "crystal-beacon", name: "Crystal Beacon" },
  { id: "crystal-oscillator", name: "Crystal Oscillator" },
  { id: "flexible-framework", name: "Flexible Framework" },
  { id: "gas-filter", name: "Gas Filter" },
  { id: "heavy-encased-frame", name: "Heavy Encased Frame" },
  { id: "heavy-flexible-frame", name: "Heavy Flexible Frame" },
  { id: "heavy-modular-frame", name: "Heavy Modular Frame" },
  { id: "high-speed-connector", name: "High-Speed Connector" },
  { id: "infused-uranium-cell", name: "Infused Uranium Cell" },
  { id: "insulated-crystal-oscillator", name: "Insulated Crystal Oscillator" },
  { id: "iodine-infused-filter", name: "Iodine Infused Filter" },
  { id: "magnetic-field-generator", name: "Magnetic Field Generator" },
  { id: "modular-engine", name: "Modular Engine" },
  { id: "plastic-smart-plating", name: "Plastic Smart Plating" },
  { id: "plutonium-fuel-rod", name: "Plutonium Fuel Rod" },
  { id: "radio-connection-unit", name: "Radio Connection Unit" },
  { id: "radio-control-system", name: "Radio Control System" },
  { id: "radio-control-unit", name: "Radio Control Unit" },
  { id: "rifle-cartridge", name: "Rifle Cartridge" },
  { id: "rigour-motor", name: "Rigour Motor" },
  { id: "seismic-nobelisk", name: "Seismic Nobelisk" },
  { id: "silicon-high-speed-connector", name: "Silicon High-Speed Connector" },
  { id: "super-state-computer", name: "Super-State Computer" },
  { id: "supercomputer", name: "Supercomputer" },
  { id: "thermal-propulsion-rocket", name: "Thermal Propulsion Rocket" },
  { id: "turbo-electric-motor", name: "Turbo Electric Motor" },
  { id: "turbo-motor", name: "Turbo Motor" },
  { id: "turbo-pressure-motor", name: "Turbo Pressure Motor" },
  { id: "uranium-fuel-rod", name: "Uranium Fuel Rod" },
  { id: "uranium-fuel-unit", name: "Uranium Fuel Unit" },
  { id: "blue-ficsmas-ornament", name: "Blue FICSMAS Ornament" },
  { id: "caterium-ingot", name: "Caterium Ingot" },
  { id: "copper-ingot", name: "Copper Ingot" },
  { id: "iron-ingot", name: "Iron Ingot" },
  { id: "pure-aluminum-ingot", name: "Pure Aluminum Ingot" },
  { id: "red-ficsmas-ornament", name: "Red FICSMAS Ornament" },
  { id: "mining-iron-mk-1", name: "Mining Iron Mk.1" },
  { id: "mining-iron-mk-2", name: "Mining Iron Mk.2" },
  { id: "mining-iron-mk-3", name: "Mining Iron Mk.3" },
  { id: "mining-copper-mk-1", name: "Mining Copper Mk.1" },
  { id: "mining-copper-mk-2", name: "Mining Copper Mk.2" },
  { id: "mining-copper-mk-3", name: "Mining Copper Mk.3" },
  { id: "mining-limestone-mk-1", name: "Mining Limestone Mk.1" },
  { id: "mining-limestone-mk-2", name: "Mining Limestone Mk.2" },
  { id: "mining-limestone-mk-3", name: "Mining Limestone Mk.3" },
  { id: "mining-coal-mk-1", name: "Mining Coal Mk.1" },
  { id: "mining-coal-mk-2", name: "Mining Coal Mk.2" },
  { id: "mining-coal-mk-3", name: "Mining Coal Mk.3" },
  { id: "mining-caterium-mk-1", name: "Mining Caterium Mk.1" },
  { id: "mining-caterium-mk-2", name: "Mining Caterium Mk.2" },
  { id: "mining-caterium-mk-3", name: "Mining Caterium Mk.3" },
  { id: "mining-quartz-mk-1", name: "Mining Quartz Mk.1" },
  { id: "mining-quartz-mk-2", name: "Mining Quartz Mk.2" },
  { id: "mining-quartz-mk-3", name: "Mining Quartz Mk.3" },
  { id: "mining-sulfur-mk-1", name: "Mining Sulfur Mk.1" },
  { id: "mining-sulfur-mk-2", name: "Mining Sulfur Mk.2" },
  { id: "mining-sulfur-mk-3", name: "Mining Sulfur Mk.3" },
  { id: "mining-bauxite-mk-1", name: "Mining Bauxite Mk.1" },
  { id: "mining-bauxite-mk-2", name: "Mining Bauxite Mk.2" },
  { id: "mining-bauxite-mk-3", name: "Mining Bauxite Mk.3" },
  { id: "mining-uranium-mk-1", name: "Mining Uranium Mk.1" },
  { id: "mining-uranium-mk-2", name: "Mining Uranium Mk.2" },
  { id: "-ining-uranium-mk-3", name: "Mining Uranium Mk.3" },
  { id: "mining-sam-mk-1", name: "Mining SAM Mk.1" },
  { id: "mining-sam-mk-2", name: "Mining SAM Mk.2" },
  { id: "mining-sam-mk-3", name: "Mining SAM Mk.3" },
  { id: "extract-oil", name: "Extract Oil" },
  { id: "extract-water", name: "Extract Water" },
  { id: "extract-water-from-resource-well", name: "Extract Water From Resource Well" },
  { id: "extract-nitrogen-gas-from-resource-well", name: "Extract Nitrogen Gas From Resource Well" },
  { id: "extract-crude-oil-from-resource-well", name: "Extract Crude Oil From Resource Well" }
]

export default class V2toV3 extends Converter {
  inputVersion: number = 2;
  outputVersion: number = 3;

  convertSave(save: SaveFormatV2): SaveFormatV3 {
    let newSave: SaveFormatV3 = {
      name: save.name,
      version: save.version,
      template: save.template,
      factory: {
        connections: save.factory.connections,
        elements: save.factory.elements.map((e: FactoryElementParams) => {
          return {
            position: e.position,
            number: e.number,
            id: e.id,
            recipeId: recipesTransitionTable.find((v: { id: string, name: string }) => v.name === e.type)!.id,
            inputSockets: e.inputSockets,
            outputSockets: e.outputSockets,
            efficiency: e.efficiency
          }
        })
      }
    };

    return newSave;
  }
}