import classNames from 'classnames';

import './CardsSpacer.scss';

export default function CardsSpacer(props: {
  id: number;
  isResizing: boolean;
  startResizing: (index: number) => void;
  endResizing: (index: number) => void;
}) {

  function onMouseDown(e: any) {
    if(e.buttons === 1) {
      props.startResizing(props.id);
    }
  }

  function onMouseUp(e: any) {
    if(e.buttons !== 1) {
      props.endResizing(props.id);
    }
  }

  if (props.id > 0) {
    return(
      <div className={classNames("EditorCard-pre", props.isResizing ? "Resizing" : "")} onMouseDown={onMouseDown} onMouseUp={onMouseUp}></div>
    );
  } else {
    return <></>;
  }
}