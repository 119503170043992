// external:
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { doc, Firestore, updateDoc } from "firebase/firestore";
// internal:
import { Building } from '../Model/Building';
import { FactoryConnection, Socket } from '../Model/FactoryConnection';
import FactoryElement, { FactoryElementParameters, Position } from '../Model/FactoryElement';
import { ID } from '../Model/ID';
import { Item, ItemAmount } from '../Model/Item';
import { Recipe } from '../Model/Recipe';
import { GetSatisfactoryTemplate } from '../Data/DataLoader';
import SaveFormat from '../Model/SaveHandler/SaveFormat';
import convertToCurrentVersion from '../Model/SaveHandler/SaveConverter';
import generateCurrentSavefile from '../Model/SaveHandler/generateCurrentSavefile';
import { CurrentFormat } from '../Model/SaveHandler/Current';
import { FactoryElementParams2 } from '../Model/SaveHandler/Versions/V3';
import { FactoryConnectionParams } from '../Model/SaveHandler/Versions/V1';

export function getRecipeSocketVelocity(r: Recipe, socket: number, io: "i" | "o") {
  return (io === "i" ? r.ingredients : r.products)[socket].amount * (60 / r.executionTimeInSec);
}

function calculateEfficiency(state: FactoryState) {
  const itemsChangedCurrentBigIteration: {id: ID, eff: number}[] = [];

  state.elements.forEach((e: FactoryElement) => {
    e.efficiency = 2;
  });
  
  do {
    //console.log("#### > BIG ITERATION < ####");
    const calculatedElementsStack: ID[] = [];
    const elementsCalculatedInCurrentIteration: ID[] = [];

    itemsChangedCurrentBigIteration.splice(0, itemsChangedCurrentBigIteration.length);
    do {
      //console.log("### FRONT ITERATION ###")
      calculatedElementsStack.push(...elementsCalculatedInCurrentIteration);
      elementsCalculatedInCurrentIteration.splice(0, elementsCalculatedInCurrentIteration.length);

      state.elements.forEach((e: FactoryElement) => {
        //console.log(`enter: %c${e.type}`, "color:green");
        if (!calculatedElementsStack.includes(e.id)) {
          const recipe = e.getRecipe(state);
          const inputs = e.getInputs(state);

          const areAllInputsConnected = inputs.length === recipe.ingredients.length;
          const areAllInputsCalculated = !(inputs.map((conn: FactoryConnection) => calculatedElementsStack.includes(conn.start.element)).includes(false));

          if ((recipe.ingredients.length === 0) || (areAllInputsConnected && areAllInputsCalculated)) {
            const newEfficiency = Math.min(e.efficiency, e.getEfficiencyCappedByInputs(state));
            if (Math.abs(e.efficiency - newEfficiency) > 0.0001) {
              //console.log("calculate");
              itemsChangedCurrentBigIteration.push({id: e.id, eff: newEfficiency});
              elementsCalculatedInCurrentIteration.push(e.id);
              e.efficiency = newEfficiency;
            }
          } else if (!areAllInputsConnected) {
            //console.log("not connected");
            const newEfficiency = 0;
            if (Math.abs(e.efficiency - newEfficiency) > 0.0001) {
              //console.log("saved");
              itemsChangedCurrentBigIteration.push({id: e.id, eff: newEfficiency});
              elementsCalculatedInCurrentIteration.push(e.id);
              e.efficiency = newEfficiency;
            }
          }
        }
      });
    } while (elementsCalculatedInCurrentIteration.length > 0);

    calculatedElementsStack.splice(0, calculatedElementsStack.length);
    
    do {
      //console.log("### BACK ITERATION ###")
      calculatedElementsStack.push(...elementsCalculatedInCurrentIteration);
      elementsCalculatedInCurrentIteration.splice(0, elementsCalculatedInCurrentIteration.length);
      state.elements.forEach((e: FactoryElement) => {
        //console.log(`enter: %c${e.type}`, "color:green");
        if (!calculatedElementsStack.includes(e.id)) {
          const recipe = e.getRecipe(state);
          const outputs = e.getOutputs(state);

          const areAllOutputsConnected = outputs.length === recipe.products.length;
          const areAllOutputsCalculated = !(outputs.map((conn: FactoryConnection) => calculatedElementsStack.includes(conn.end.element)).includes(false));

          if (outputs.length === 0 || (areAllOutputsConnected && areAllOutputsCalculated)) {
            const newEfficiency = Math.min(e.efficiency, e.getEfficiencyCappedByOutputs(state));
            if (Math.abs(e.efficiency - newEfficiency) > 0.0001) {
              //console.log('calculate');
              itemsChangedCurrentBigIteration.push({id: e.id, eff: newEfficiency});
              elementsCalculatedInCurrentIteration.push(e.id);
              e.efficiency = newEfficiency;
            } else {
              //console.log('no change')
              elementsCalculatedInCurrentIteration.push(e.id);
            }
          } else if (!areAllOutputsConnected) {
            //console.log('not connected');
            calculatedElementsStack.push(e.id);
          }
        }
      });
    } while (elementsCalculatedInCurrentIteration.length > 0);
  } while (itemsChangedCurrentBigIteration.length > 0);
}

export interface SatisfactoryMilestone {
  name: string,
  cost: ItemAmount[],
  buildings: string[],
  recipes: string[],
  equipment:  string[],
  scannerUpdate: string[],
  upgrades: string[]
}

export interface SatisfactoryTier {
  id: string;
  name: string;
  milestones: SatisfactoryMilestone[];
}

export interface FactoryTemplate {
  name: string;
  items: Item[];
  buildings: Building[];
  recipes: Recipe[];
  techtree: { tiers: SatisfactoryTier[] };
}

export interface FactoryLayout {
  elements: FactoryElement[];
  connections: FactoryConnection[];
}

export interface FactoryData {
  template: string;
  name: string;
  factory: FactoryLayout;
}

enum CloudStatus {
  Synced = "synced to cloud",
  NotSynced = "not synced",
  Syncing = "syncing",
  Unknown = ""
}

export interface FactoryState {
  id: any;
  name: string;
  status: CloudStatus;
  items: Item[];
  buildings: Building[];
  recipes: Recipe[];
  elements: FactoryElement[];
  connections: FactoryConnection[];
  techtree: {tiers: SatisfactoryTier[]; };
  type: "cloud" | "file" | "";
}

const initialState: FactoryState = {
  id: "",
  name: "",
  status: CloudStatus.Unknown,
  elements: [],
  connections: [],
  items: [],
  buildings: [],
  recipes: [],
  techtree: { tiers: [] },
  type: "",
};

export interface FactoryConnectionParameters {
  start: Socket;
  end: Socket;
}

function getNewConnectionId(state: FactoryState): ID {
  let newId = ""; 

  const isIdUnique = (id: string): boolean => {
    return (state.connections.findIndex((c: FactoryConnection) => c.id === id) === -1)
  }

  do {
    newId = "c" + (Math.random()*100000).toFixed(0);
  } while (!isIdUnique(newId))
  return newId;
}

function getNewElementId(state: FactoryState): ID {
  let newId = ""; 

  const isIdUnique = (id: string): boolean => {
    return (state.elements.findIndex((c: FactoryElement) => c.id === id) === -1)
  }

  do {
    newId = "c" + (Math.random()*100000).toFixed(0);
  } while (!isIdUnique(newId))
  return newId;
}

export const factorySlice = createSlice({
  name: 'factory',
  initialState,
  reducers: {
    addItem: (state, action: PayloadAction<Item>) => {
      state.items.push(action.payload);
    },
    clearItems: (state) => {
      state.items.splice(0, state.items.length);
    },
    addBuilding: (state, action: PayloadAction<Building>) => {
      state.buildings.push(action.payload);
    },
    clearBuildings: (state) => {
      state.buildings.splice(0, state.buildings.length);
    },
    addRecipe: (state, action: PayloadAction<Recipe>) => {
      state.recipes.push(action.payload);
    },
    clearRecipes: (state) => {
      state.recipes.splice(0, state.recipes.length);
    },
    addElement: (state, action: PayloadAction<FactoryElementParameters>) => {
      state.elements.push(new FactoryElement({
        id: getNewElementId(state),
        position: { x: action.payload.position.x, y: action.payload.position.y },
        number: action.payload.number,
        recipeId: action.payload.recipeId,
        inputSockets: [],
        outputSockets: [],
        efficiency: 0
      }));
      calculateEfficiency(state);
    },
    deleteElement: (state, action: PayloadAction<string>) => {
      const elemIndex = state.elements.findIndex((e: FactoryElement) => e.id === action.payload)
      if (elemIndex !== -1) {
        state.elements.splice(elemIndex, 1);

        const connsToRemove = state.connections.filter((conn: FactoryConnection) => ((conn.end.element === action.payload) || (conn.start.element === action.payload)));
        connsToRemove.forEach((connToRemove: FactoryConnection) => {
          const connIndex = state.connections.findIndex((conn: FactoryConnection) => conn.id === connToRemove.id);
          state.connections.splice(connIndex, 1);
        })
      }
      calculateEfficiency(state);
    },
    clearElements: (state) => {
      state.elements.splice(0, state.elements.length);
    },
    setElementPosition: (state, action: PayloadAction<{ id: ID, pos: Position }>) => {
      const el = state.elements.find((e: FactoryElement) => e.id === action.payload.id);
      if (el) {
        el.position = action.payload.pos;
      }
    },
    addConnection: (state, action: PayloadAction<FactoryConnectionParameters>) => {
      state.connections = [...state.connections.filter((c: FactoryConnection) => 
        ((action.payload.start.element !== c.start.element) || (action.payload.start.socket !== c.start.socket)) 
        && ((action.payload.end.element !== c.end.element) || (action.payload.end.socket !== c.end.socket))
        )];

      state.connections.push(new FactoryConnection({
          ...action.payload, 
          id: getNewConnectionId(state)
        }));
      calculateEfficiency(state);
    },
    deleteConnection: (state, action: PayloadAction<string>) => {
      const connIndex = state.connections.findIndex((conn: FactoryConnection) => conn.id === action.payload);
      if (connIndex !== -1) {
        state.connections.splice(connIndex, 1);
      }
      calculateEfficiency(state);
    },
    loadFactoryData: (state, action: PayloadAction<{ template: FactoryTemplate, factory: CurrentFormat, id: any, type: "cloud" | "file" | "" }>) => {
      state.id = action.payload.id;
      state.name = action.payload.factory.name;
      state.buildings = action.payload.template.buildings;
      state.recipes = action.payload.template.recipes;
      state.items = action.payload.template.items;
      state.elements = action.payload.factory.factory.elements.map((v: FactoryElementParams2) => new FactoryElement(v));
      state.connections = action.payload.factory.factory.connections.map((v: FactoryConnectionParams) => new FactoryConnection(v));
      state.techtree = action.payload.template.techtree;
      state.type = action.payload.type;
      if (action.payload.type === "cloud") {
        state.status = CloudStatus.Synced;
      } else {
        state.status = CloudStatus.NotSynced
      }
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setStatus: (state, action: PayloadAction<CloudStatus>) => {
      state.status = action.payload;
    },
    clearFactory: (state) => {
      state.id = "";
      state.name = "";
      state.buildings = [];
      state.recipes = [];
      state.items = [];
      state.elements = [];
      state.connections = [];
      state.type = "";
      state.status = CloudStatus.Unknown;
    },
    setStateAndId: (state, action: PayloadAction<{id: string, type: "cloud" | "file" | ""}>) => {
      state.id = action.payload.id;
      state.type = action.payload.type;
      if (state.type === "cloud") {
        state.status = CloudStatus.Synced;
      } else {
        state.status = CloudStatus.NotSynced;
      }
    },
    setElementNumber: (state, action: PayloadAction<{id: string, number: number}>) => {
      const el = state.elements.find((el: FactoryElement) => el.id === action.payload.id);
      if (el) {
        el.number = action.payload.number;
      }
      calculateEfficiency(state);
    }
  }
});

export function syncWithServer(action: any, firestore: Firestore) {
  return async function thunk(dispatch: any, getState: any) {
    await dispatch(action);
    if (getState().factory.type === "cloud") {
      dispatch(setStatus(CloudStatus.Syncing));
      if (getState().factory.id !== "") {

        const save = generateCurrentSavefile(getState().factory);
        const docRef = doc(firestore, "factories", getState().factory.id);

        await updateDoc(docRef, {
          name: save.name,
          version: save.version,
          factory: save.factory
        });
        dispatch(setStatus(CloudStatus.Synced));
      }
    }
  }
}

export function addElementWithLink(el: FactoryElementParameters, socketToConnect: Socket) {
  return async function thunk(dispatch: any, getState: any) {
    await dispatch(addElement(el));
    const socketElement: FactoryElement = getState().factory.elements.find((e: FactoryElement) => e.id === socketToConnect.element);
    const newElement: FactoryElement = getState().factory.elements[getState().factory.elements.length - 1];
    let itemName: string = "";

    if (socketElement && newElement) {
      if (socketToConnect.type === "I") {
        itemName = socketElement.getRecipe(getState().factory).ingredients[socketToConnect.socket].item;
        const newSocketId = newElement.getRecipe(getState().factory).products.findIndex((ia: ItemAmount) => ia.item === itemName)
        const newSocket: Socket = {
          element: newElement.id,
          type: "O",
          socket: newSocketId,
        };
        dispatch(addConnection({
          start: newSocket,
          end: socketToConnect
        }));
      } else {
        itemName = socketElement.getRecipe(getState().factory).products[socketToConnect.socket].item;
        const newSocketId = newElement.getRecipe(getState().factory).ingredients.findIndex((ia: ItemAmount) => ia.item === itemName)
        const newSocket: Socket = {
          element: newElement.id,
          type: "I",
          socket: newSocketId,
        };
        dispatch(addConnection({
          start: socketToConnect,
          end: newSocket
        }));
      }
    }
  }
}

function getTemplate(template: string): any { 
  if (template === "Satisfactory") {
    return GetSatisfactoryTemplate();
  }
}

export function loadFactoryFromSave(save: SaveFormat, type: "" | "cloud" | "file", id: string) {
  return async function thunk(dispatch: any, getState: any) {
    const currentSave = convertToCurrentVersion(save);
    dispatch(loadFactoryData({ template: getTemplate(currentSave.template), factory: currentSave, id: id, type: type }));
  }
}

export const { addItem, clearItems,
  addBuilding, clearBuildings,
  addRecipe, clearRecipes, setName,
  addElement, clearElements, setElementPosition, deleteElement,
  addConnection, deleteConnection,
  loadFactoryData, setStatus, clearFactory, setStateAndId, setElementNumber } = factorySlice.actions;

export default factorySlice.reducer;