import { FactoryState, getRecipeSocketVelocity } from '../Redux/FactorySlice';
import FactoryElement from './FactoryElement';
import { ID } from './ID';
import { ItemAmount } from './Item';
import { FactoryConnectionParams } from './SaveHandler/Versions/V1';

export interface Socket {
  element: ID,
  type: string;
  socket: number;
}

export class FactoryConnection {
  id: ID;
  start: Socket;
  end: Socket;

  constructor(c: FactoryConnectionParams) {
    this.id = c.id;
    this.start = c.start;
    this.end = c.end;
  }

  serialize(): FactoryConnectionParams {
    return {
      id: this.id,
      start: this.start,
      end: this.end
    };
  }

  getStartElement(state: FactoryState): FactoryElement | undefined {
    return state.elements.find((e: FactoryElement) => e.id === this.start.element);
  }
  
  getEndElement(state: FactoryState): FactoryElement | undefined {
    return state.elements.find((e: FactoryElement) => e.id === this.end.element);
  }

  getContent(state: FactoryState): ItemAmount {
    let ia: ItemAmount = {item: "", amount: 0};
    const startElement = this.getStartElement(state)!;
    const startElementRecipe = startElement.getRecipe(state);
    ia = {
      item: startElementRecipe.products[this.start.socket].item,
      amount: getRecipeSocketVelocity(startElementRecipe, this.start.socket, "o") * startElement.efficiency * startElement.number
    }
    return ia;
  }
  
  getConsumption(state: FactoryState): ItemAmount {
    let ia: ItemAmount = {item: "", amount: 0};
    const endElement = this.getEndElement(state)!;
    const endElementRecipe = endElement.getRecipe(state);
    ia = {
      item: endElementRecipe.ingredients[this.start.socket].item,
      amount: getRecipeSocketVelocity(endElementRecipe, this.end.socket, "i") * endElement.efficiency * endElement.number
    }
    return ia;
  }
}