// external:
import { Firestore } from 'firebase/firestore';
// internal:
import FactoryCanvas from './Components/FactoryCanvas/FactoryCanvas';
import AssetBrowser from './Components/AssetBrowser/AssetBrowser';
import FactorySidePanel from './Components/FactorySidePanel/FactorySidePanel';
import { ContextMenuData } from '../../../Components/ContextMenu/ContextMenu';
// styling:
import "./FactoryEditorPage.scss";
import EditorUI from '../../../Components/EditorUI/EditorUI';
import { SectionConfig } from '../../../Components/EditorUI/EditorComponentFactory/EditorComponentFactory';
import { useState } from 'react';

function FactoryEditorPage(props: {
  firestore: Firestore;
  contextMenu: (context: ContextMenuData) => void;
}) {
  const primitives = [
    {
      id: "canvas", 
      name: "Factory Canvas",
      element: <FactoryCanvas firestore={props.firestore} contextMenu={props.contextMenu}/>
    },
    {
      id: "sidepanel",
      name: "Editor Side Panel",
      element: <FactorySidePanel/>
    },
    {
      id: "assetbrowser", 
      name: "Recipes Browser",
      element: <AssetBrowser/>
    }
  ];

  const [sections] = useState<SectionConfig[]>([
      {
        id:"top",
        type:"horizontal",
        elements: [
          {
            id: "canvas",
            weight: 0.8,
            minSize:"50%"
          }, {
            id: "sidepanel",
            weight: 0.2,
            minSize: "350px",
          }
        ]
      }, {
        id: "root",
        type: "vertical",
        elements: [
          {
            id: "top", 
            weight: 0.6
          }, {
            id: "assetbrowser", 
            weight: 0.4,
            maxSize: "60%"
          }
        ]
      }
    ]);

  return <EditorUI primitives={primitives} initialSections={sections}/>;
}

export default FactoryEditorPage;