// internal:
import { useAppSelector } from "../../../Redux/hooks";
import { RootState } from "../../../Redux/store";
import { Item } from '../../../Model/Item';
import CardsBrowser from "../../../Components/CardsBrowser/CardsBrowser";
import ItemCard from '../../../Components/Cards/ItemCard/ItemCard';
import SingleItemPage from "../SingleItemPage/SingleItemPage";
import { useState } from "react";
import { SectionConfig } from "../../../Components/EditorUI/EditorComponentFactory/EditorComponentFactory";
import EditorUI from "../../../Components/EditorUI/EditorUI";

function ItemsListPage() {
  const [selectedItem, setSelectedItem] = useState("bauxite"); 

  const items = useAppSelector((state: RootState) => state.factory.items);
  const itemsDraw = items.map(
    (i: Item) => <ItemCard key={i.id} item={i} selected={selectedItem} select={setSelectedItem}/>
  );

  const primitives = [
    {
      id: "cards", 
      name: "Cards",
      element: <CardsBrowser>{itemsDraw}</CardsBrowser>
    },
    {
      id: "sidepanel",
      name: "Editor Side Panel",
      element: <SingleItemPage item={selectedItem}/>
    }
  ];

  const [sections] = useState<SectionConfig[]>([
    {
      id:"root",
      type:"horizontal",
      elements: [
        {
          id: "cards",
          weight: 0.5,
          minSize:"50%"
        }, {
          id: "sidepanel",
          weight: 0.5,
          minSize: "350px",
        }
      ]
    }
  ]);

  return <EditorUI primitives={primitives} initialSections={sections}/>
}

export default ItemsListPage;