import classNames from "classnames";
import { SatisfactoryMilestone } from "../../../../Redux/FactorySlice";
// styling:
import './SatisfactoryMilestoneDraw.scss';

export default function SatisfactoryMilestoneDraw(props: {
  milestone: SatisfactoryMilestone;
  selectedMilestone: SatisfactoryMilestone;
  selectMilestone: (sm: SatisfactoryMilestone) => void;
}) {
  function onClick(e: any) {
    props.selectMilestone(props.milestone);
  }

  return (
    <>
      <div className={classNames("SatisfactoryMilestoneDraw-title", props.milestone === props.selectedMilestone ? "selected" : "")} onClick={onClick}>
        {props.milestone.name}
      </div>
    </>
  );
}