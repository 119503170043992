import ItemCost from "../../../../Components/ItemCost/ItemCost";
import PropRow from "../../../../Components/PropsTable/PropRow/PropRow";
import PropsTable from "../../../../Components/PropsTable/PropsTable";
import TitleRow from "../../../../Components/PropsTable/TitleRow/TitleRow";
import { ItemAmount } from "../../../../Model/Item";
import { SatisfactoryMilestone, SatisfactoryTier } from "../../../../Redux/FactorySlice";

export default function SatisfactoryMilestoneDetailsDraw(props: {
  tier: SatisfactoryTier;
  milestone: SatisfactoryMilestone;
}) {
  const costDraw = props.milestone.cost.map((ia: ItemAmount) => <ItemCost ia={ia}/>);
  const buildingsDraw = props.milestone.buildings.map((v: string) => <div>{v}</div>);
  const recipesDraw = props.milestone.recipes.map((v: string) => <div>{v}</div>);
  const equipmentDraw = props.milestone.equipment.map((v: string) => <div>{v}</div>);
  const scannerUpdateDraw = props.milestone.scannerUpdate.map((v: string) => <div>{v}</div>);
  const upgradesDraw = props.milestone.upgrades.map((v: string) => <div>{v}</div>);


  if (props.tier && props.milestone) {
    return (
      <div className="SatisfactoryMilestoneDetailsDraw">
        <PropsTable>
          <TitleRow>
            {props.tier.name} - {props.milestone.name}
          </TitleRow>
          <PropRow name="Cost" value={costDraw}/>
          <PropRow name="Unlocked Buildings" value={buildingsDraw} isHidden={props.milestone.buildings.length === 0}/>
          <PropRow name="Unlocked Recipes" value={recipesDraw} isHidden={props.milestone.recipes.length === 0}/>
          <PropRow name="Unlocked Equipment" value={equipmentDraw} isHidden={props.milestone.equipment.length === 0}/>
          <PropRow name="Unlocked Scanner Resources" value={scannerUpdateDraw} isHidden={props.milestone.scannerUpdate.length === 0}/>
          <PropRow name="Unlocked Upgrades" value={upgradesDraw} isHidden={props.milestone.upgrades.length === 0}/>
        </PropsTable>
      </div>
    );
  } else {
    return <div></div>
  }
}