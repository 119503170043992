// external:
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import CookieConsent from "react-cookie-consent";
import { mdiCookieAlert } from "@mdi/js";
import Icon from "@mdi/react";
import ReactGA from "react-ga4";
// internal:
import FactoryEditorPage from './Satisfactory/Pages/FactoryPlanner/Editor/FactoryEditorPage';
import ItemsPage from './Satisfactory/Pages/Items/ItemsPage';
import ItemsListPage from './Satisfactory/Pages/Items/ItemsListPage/ItemsListPage';
import BuildingsPage from './Satisfactory/Pages/Buildings/BuildingsPage';
import BuildingsListPage from './Satisfactory/Pages/Buildings/BuildingsListPage/BuildingsListPage';
import FactoryPlannerPage from './Satisfactory/Pages/FactoryPlanner/FactoryPlannerPage';
import NavBar from './Satisfactory/Components/NavBar/NavBar';
import WelcomePage from './Satisfactory/Pages/Welcome/WelcomePage';
import NewFactoryPage from './Satisfactory/Pages/FactoryPlanner/NewFactoryPage';
import ContextMenu, { clearContextMenuData, ContextMenuData } from "./Satisfactory/Components/ContextMenu/ContextMenu";
import UserPanel from "./Satisfactory/Components/UserPanel/UserPanel";
// styling:
import './App.scss';
import Prompt from "./Satisfactory/Components/Prompt/Prompt";
import TechTreePage from "./Satisfactory/Pages/TechTree/TechTreePage";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyAnc2DwUoRUUCg-2IphrGDM0im30xUxfx0",
  authDomain: "factory-modeler.firebaseapp.com",
  projectId: "factory-modeler",
  storageBucket: "factory-modeler.appspot.com",
  messagingSenderId: "424132106960",
  appId: "1:424132106960:web:02112ed7a2e3bc5a349cd5",
  measurementId: "G-D5P6EWWXW5"
});

const auth = getAuth(firebaseApp);
const firestore = getFirestore(firebaseApp);

function App() {
  const [promptTitle, setPromptTitle] = useState("");
  const [promptContent, setPromptContent] = useState("");
  const [isPromptVisible, setIsPromptVisible] = useState(false);
  const [onAccepted, setOnAccepted] = useState(() => () => {});
  const [onCanceled, setOnCanceled] = useState(() => () => {});
  const [contextMenuData, setContextMenuData] = useState<ContextMenuData>(clearContextMenuData())

  function showPrompt(title: string, content: string, onAccepted: () => void) {
    setPromptTitle(title);
    setPromptContent(content);
    setOnAccepted(() => onAccepted);
    setOnCanceled(() => () => {});
    setIsPromptVisible(true);
  }

  useEffect(() => {
    ReactGA.initialize("G-D5P6EWWXW5");
    ReactGA.send("pageview");
  })

  return (
    <div className="App">
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="cogler-app-cookie"
        style={{ background: "rgb(80,80,80)", fontWeight: 600, borderTop: "1px solid rgb(255,255,120)", paddingTop: "10px", paddingBottom: "30px", color: "rgb(255,255,120)"}}
        buttonStyle={{ color: "var(--ui-bg-primary)", fontSize: "13px", backgroundColor: "rgb(255,255,120)", fontWeight: 600}}
        expires={150}
      >
        <div><div><Icon path={mdiCookieAlert} size={4} color="rgb(255,255,120)"/></div><div style={{margin: "auto"}}>This website uses cookies to enhance the user experience.</div></div>
      </CookieConsent>
      <Router>
        <NavBar>
          <UserPanel auth={auth}/>
        </NavBar>
        <Routes>
          <Route path="factory">
            <Route path=":factoryId" element={<FactoryPlannerPage firestore={firestore} auth={auth}/>}>
              <Route path="items" element={<ItemsPage/>}>
                <Route index element={<ItemsListPage/>} />
              </Route>
              <Route path="buildings" element={<BuildingsPage/>}>
                <Route index element={<BuildingsListPage/>}/>
              </Route>
              <Route path="techtree" element={<TechTreePage/>}/>
              <Route index element={<FactoryEditorPage firestore={firestore} contextMenu={setContextMenuData}/>}/>
            </Route>
            <Route path="new" element={<NewFactoryPage firestore={firestore} auth={auth}/>}/>
          </Route>
          <Route path="*" element={<WelcomePage firestore={firestore} auth={auth} showPrompt={showPrompt}/>}/>
        </Routes>
      </Router>
      <Prompt isVisible={isPromptVisible} title={promptTitle} content={promptContent} onAccepted={() => {setIsPromptVisible(false); onAccepted()}} onCanceled={() => {setIsPromptVisible(false); onCanceled()}}/>
      <ContextMenu context={contextMenuData}/>
    </div>
  ); 
}

export default App;