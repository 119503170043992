// styling:
import './NavBarMenu.scss';

export default function NavBarMenu(props: {
  children?: any
}) {
  return (
    <div className="NavBarMenu">
      <nav>
        {props.children}
      </nav>
    </div>
  );
}