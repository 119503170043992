import classNames from "classnames";
import React, { useContext, useRef, useState } from "react";
import './EditorSection.scss';
import CardsSpacer from "../CardsSpacer/CardsSpacer";
import EditorCard from "../EditorCard/EditorCard";
import EditorComponentFactory, { SectionConfig, ElementConfig } from "../EditorComponentFactory/EditorComponentFactory";
import { EditorContext } from "../EditorContext/EditorContext";

function EditorCardsSection(props: {
  children?: any;
  className?: string;
  config: SectionConfig;
  sections: SectionConfig[];
}) {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  const currentCardContent = <EditorCard config={props.config.elements[currentCardIndex]}>
                              <EditorComponentFactory id={props.config.elements[currentCardIndex].id} sections={props.sections}/>
                             </EditorCard>;

  const cards = props.config.elements.map((c: ElementConfig, index: number) =>
    <div className={classNames("EditorSection-card", (index === currentCardIndex) ? "current" : "", "noselect")} onClick={() => setCurrentCardIndex(index)}>
      {c.id}
    </div>
  )

  return (
    <div className={classNames("EditorCardsSection", "cards")}>
      <div className={"EditorSection-cards"}>
        {cards}
      </div>
      {currentCardContent}
    </div>
  );
}

export default function EditorSection(props: {
  children?: any;
  className?: string;
  config: SectionConfig;
  sections: SectionConfig[];
}) {
  const sectionRef = useRef<HTMLDivElement>(null);
  const [currentlyResizing, setCurrentlyResizing] = useState(-1);
  const editorContext = useContext(EditorContext);

  function setResizedSpacer(index: number) {
    setCurrentlyResizing(index);
  }

  function stopResizing(index: number) {
    if (currentlyResizing === index) {
      setCurrentlyResizing(-1);
    }
  }

  const elements = props.config.elements.map((value: ElementConfig, index: number) => 
    <React.Fragment key={value.id}>
      <CardsSpacer id={index} startResizing={setResizedSpacer} endResizing={stopResizing} isResizing={currentlyResizing===index}/>
      <EditorCard config={value}>
        <EditorComponentFactory id={value.id} sections={props.sections}/>
      </EditorCard>
    </React.Fragment>
  );

  function onMouseMove(e: any) {
    if (sectionRef.current && e.buttons === 1) {
      const box = sectionRef.current.getBoundingClientRect();
      if (box) {
        const widthPercent = (e.pageX - box.left)/box.width;
        const heightPercent = (e.pageY - box.top)/box.height;
        if (currentlyResizing > 0) {
          const newPreSpacerPercent = props.config.type === "vertical" ? heightPercent : widthPercent;
          const newPostSpacerPercent = 1 - newPreSpacerPercent;

          const preSpacerElements = props.config.elements.filter((value: ElementConfig, index: number) => index < currentlyResizing);
          const postSpacerElements = props.config.elements.filter((value: ElementConfig, index: number) => index >= currentlyResizing); 

          let oldPreSpacerPercent = 0;
          preSpacerElements.forEach((value: ElementConfig) => { oldPreSpacerPercent += (value.weight ?? 1)});
          let oldPostSpacerPercent = 1 - oldPreSpacerPercent;
          
          preSpacerElements.forEach((value: ElementConfig) => {
            editorContext.updateSectionElement(props.config.id, value.id, ((value.weight ?? 1)/oldPreSpacerPercent)*newPreSpacerPercent);
          });
          
          postSpacerElements.forEach((value: ElementConfig) => {
            editorContext.updateSectionElement(props.config.id, value.id, ((value.weight ?? 1)/oldPostSpacerPercent)*newPostSpacerPercent);
          });
        }
      }
    }
  }

  function onMouseUp(e: any) {
    if (currentlyResizing > 0) {
      setCurrentlyResizing(-1);
    }
  }
  if (props.config.type === "cards") {
    return (<EditorCardsSection config={props.config} sections={props.sections}/>)
  } else {
    return (
      <div className={classNames("EditorSection", props.config.type ?? "horizontal", props.className)} onMouseMove={onMouseMove} onMouseUp={onMouseUp} ref={sectionRef}>
        {elements}
      </div>
    );
  }
}