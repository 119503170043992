import { Fragment } from "react";
// styling:
import './PropRow.scss';

export default function PropRow(props: {
  value: any;
  name: string;
  isHidden?: boolean
}) {
  if(!props.isHidden){
    return (
      <Fragment>
        <div className="PropRow-name">
          {props.name}
        </div>
        <div className="PropRow-value">
          {props.value}
        </div>
      </Fragment>
    );
  } else {
    return <></>;
  }
}