// external:
import ReactTooltip from "react-tooltip";
// internal:
import SocketTooltipData from "./SocketTooltipData";

export default function SocketOutputTooltip() {
  return (
    <ReactTooltip id='socketOutputTooltip' effect="solid" place="right"
      getContent={(dataTip: string) => {
        if (dataTip === null)
          return;
        let d: SocketTooltipData = JSON.parse(dataTip);
        return (
          <div>
            <div>
              <strong>{d.velocity.item}</strong>
            </div>
            <div>
              produces {(d.velocity.amount*d.efficiency).toFixed(2)}/min 
            </div>
            <div>
              max {d.velocity.amount.toFixed(2)}/min
            </div>
          </div>
        );
      }}
    />
  );
}