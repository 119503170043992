// external:
import classNames from "classnames";

export default function LinkPath(props: {
  startPos: {x: number, y: number},
  endPos: {x: number, y: number},
  className?: string,
  endAsOutput?: boolean,
  startAsInput?: boolean,
}){
  const vertBezShift = Math.max(40,Math.min(150, Math.abs((props.startPos.x - props.endPos.x)/2)));
  return (
    <path className={classNames("LinkPath", props.className)}
      d={"M "
      +props.startPos.x+" "
      +props.startPos.y+" C "
      +(props.startPos.x+(props.startAsInput? -vertBezShift : vertBezShift))+" "
      +props.startPos.y+" "
      +(props.endPos.x+(props.endAsOutput? vertBezShift : -vertBezShift))+" "
      +props.endPos.y+" "
      +props.endPos.x+" "
      +props.endPos.y}
      fill="transparent"
    />
  );
}