// external:
import { mdiCheckboxBlankOutline, mdiCheckboxMarkedOutline } from '@mdi/js';
import Icon from '@mdi/react';
// internal:
import NavBarMenuItem from "./NavBarMenuItem";
// styling:
import './NavBarCheckboxItem.scss';

export default function NavBarCheckboxItem(props: {
  display: string;
  value: boolean;
  action?: () => void;
}) {
  const display = <>{props.value ? <Icon path={mdiCheckboxMarkedOutline} size={0.75}/> : <Icon path={mdiCheckboxBlankOutline} size={0.75}/>} {props.display}</>

  return (
    <NavBarMenuItem className="NavBarCheckboxItem" display={display} action={props.action}></NavBarMenuItem>
  );
}