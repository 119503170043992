// external:
import { useState } from 'react';
// internal:
import { useAppSelector } from '../../../../../Redux/hooks';
import { RootState } from '../../../../../Redux/store';
import { FactoryState } from '../../../../../Redux/FactorySlice';
import FactoryElement from '../../../../../Model/FactoryElement';
import { Building } from '../../../../../Model/Building';
import { Recipe } from '../../../../../Model/Recipe';
import { ItemAmount } from '../../../../../Model/Item';
import ItemCost from '../../../../../Components/ItemCost/ItemCost';
import { FactoryConnection } from '../../../../../Model/FactoryConnection';
// styling:
import './FactorySidePanel.scss';

function FactorySidePanelSection(props: {
  title: string;
  children: any;
}) {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <div className="FactorySidePanel-section">
      <div className="FactorySidePanel-section-title" onClick={() => setIsVisible((prev: boolean) => !prev)}>
        {props.title}
      </div>
      {isVisible ? <div className="FactorySidePanel-section-content">
        {props.children}
      </div> : <></>}
    </div>
  );
}

interface ItemsCollection {
  items: ItemAmount[];
}

function getBuildingByRecipeName(name: string, factory: FactoryState): Building | undefined {
  const r = factory.recipes.find((r: Recipe) => r.name === name);
  if (r)
    return factory.buildings.find((b: Building) => r.executor === b.name);
}

function calculatePowerConsumption(factory: FactoryState): {max: number, min: number} {
  let consumption = {max: 0, min: 0};

  factory.elements.forEach((e: FactoryElement) => {
    const b = getBuildingByRecipeName(e.getRecipe(factory).name, factory);
    if (b) {
      consumption.max += e.efficiency*e.number*b.powerUse.max;
      consumption.min += e.efficiency*e.number*b.powerUse.min;
    }
  })

  return consumption;
}

function addToItemsCollection(c: ItemsCollection, items: ItemAmount[], times?: number) {
  const multiply = times ? times : 1;
  items.forEach((ia: ItemAmount) => {
    const collectionItem = c.items.find((cia: ItemAmount) => cia.item === ia.item);
    if (collectionItem) {
      collectionItem.amount += ia.amount*multiply;
    } else {
      c.items.push({item: ia.item, amount: ia.amount*multiply});
    }
  })
}

function calculateFactoryCost(factory: FactoryState): ItemsCollection {
  let cost: ItemsCollection = {items: []};

  factory.elements.forEach((e: FactoryElement) => {
    const b = getBuildingByRecipeName(e.getRecipe(factory).name, factory);
    if (b) {
      addToItemsCollection(cost, b.ingredients, e.number);
    }
  })

  return cost;
}

function calculateFactoryOutput(factory: FactoryState): ItemsCollection {
  let output: ItemsCollection = {items: []};

  factory.elements.forEach((e: FactoryElement) => {
    if (e.efficiency > 0) {
      const outputs = e.getOutputs(factory);
      const recipe = e.getRecipe(factory);
      if (outputs.length < recipe.products.length) {
        recipe.products.forEach((ia: ItemAmount, index: number) => {
          if (outputs.findIndex((fc: FactoryConnection) => fc.start.socket === index) === -1) {
            addToItemsCollection(output, [ia], e.number*e.efficiency*(60/recipe.executionTimeInSec));
          }
        });
      }
    }
  })

  return output;
}

export default function FactorySidePanel() {
  const factory = useAppSelector((state: RootState) => state.factory);

  const power = calculatePowerConsumption(factory);
  const cost = calculateFactoryCost(factory);
  const output = calculateFactoryOutput(factory);
  const costDraw = cost.items.map((ia: ItemAmount) => <ItemCost key={ia.item} ia={ia}/>)
  const outputDraw = output.items.map((ia: ItemAmount) => <ItemCost key={ia.item} ia={ia}/>)

  return (
    <div className="FactorySidePanel">
      <div className="FactorySidePanel-section">
        <strong>Factory power consumption:</strong> {power.min === power.max ? power.max : power.min+"-"+power.max} MW
      </div>
      <FactorySidePanelSection title="Factory Output per minute">
        {outputDraw}
      </FactorySidePanelSection>
      <FactorySidePanelSection title="Factory Construction Cost">
        {costDraw}
      </FactorySidePanelSection>
    </div>
  );
}