// external:
import { useCallback, useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { Firestore, doc, getDoc, addDoc, collection } from 'firebase/firestore';
import { mdiDownload, mdiCloudCheck, mdiCloudSyncOutline, mdiCloudOffOutline, mdiCloudUpload } from '@mdi/js';
import Icon from '@mdi/react';
import { saveAs } from 'file-saver';
import { Auth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
// internal:
import { useAppDispatch, useAppSelector } from '../../Redux/hooks';
import { RootState } from '../../Redux/store';
import { loadFactoryFromSave, setName, setStateAndId, syncWithServer } from '../../Redux/FactorySlice';
import { usePrompt } from '../../Hooks/UsePrompt/usePrompt';
import Button from '../../Components/Button/Button';
// styling:
import "./FactoryPlannerPage.scss";
import classNames from 'classnames';
import NavBarContent from '../../Components/NavBar/NavBarContent';
import NavBarMenu from '../../Components/NavBar/NavBarMenu';
import NavBarMenuLink from '../../Components/NavBar/NavBarMenuLink';
import ViewMenu from '../../Components/Menu/ViewMenu';
import generateCurrentSavefile from '../../Model/SaveHandler/generateCurrentSavefile';
import SaveFormat from '../../Model/SaveHandler/SaveFormat';

function FactoryName(props: {
  firestore: Firestore;
}) {
  const dispatch = useAppDispatch();
  const name = useAppSelector((state: RootState) => state.factory.name);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [localName, setLocalName] = useState(name);

  useEffect(() => {
    setLocalName(name);
  }, [name])

  const adjustInputLength = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.style.width = localName.length + "ch";
    }
  }, [localName]);

  useEffect(() => {
    adjustInputLength();
  }, [localName, isInputVisible, adjustInputLength]);

  function onNameInput(e: any) {
    setLocalName(e.target.value)
  }

  function onInputKeyDown(e: any) {
    if (e.key === "Enter") {
      endEdit();
    }
  }

  function saveNameIfChanged() {
    if(localName !== name) {
      dispatch(syncWithServer(setName(localName), props.firestore));
    }
  }

  function showInput() {
    setIsInputVisible(true);
  }

  function hideInput() {
    setIsInputVisible(false);
  }

  function endEdit() {
    saveNameIfChanged();
    setIsEditing(false);
  }

  function startEdit() {
    setIsEditing(true);
  }

  return (
    <div className="FactoryName" onMouseEnter={showInput} onMouseLeave={hideInput}>
      <input className={classNames("FactoryName-input", (isInputVisible || isEditing) ? "FactoryName-visible" : "FactoryName-hidden")} ref={inputRef} type="text" value={localName} onInput={onNameInput} onKeyDown={onInputKeyDown} onBlur={endEdit} onFocus={startEdit}/>
      <span className={(isInputVisible || isEditing) ? "FactoryName-hidden" : "FactoryName-visible"}>{localName}</span>
    </div>
  );
}

function FactoryPlannerPage(props: {
  firestore: Firestore,
  auth: Auth
}) {

  let { factoryId } = useParams();
  const factory = useAppSelector((state: RootState) => state.factory);
  const isLoaded = useAppSelector((state: RootState) => state.factory.id !== "");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [user] = useAuthState(props.auth);


	usePrompt( 'Are you sure you want to leave? You are working on local file, make sure to save it on your PC before leaving.', factory.type === "file" );

  async function loadFactoryById() {
    if (factoryId && (!factory || factoryId !== factory.id)) {
      if (factoryId !== "file") {
        const docRef = doc(props.firestore, "factories", factoryId);
        const docSnap = await getDoc(docRef);

        if(docSnap.exists()) {
          dispatch(loadFactoryFromSave(docSnap.data() as SaveFormat, "cloud", factoryId))
        }
      }
    }
  }

  function saveFactory() {
    const x = JSON.stringify(generateCurrentSavefile(factory));
    const blob = new Blob([x], { type: "text/plain;charset=utf-8" });
    const date = new Date(Date.now());

    const year = date.getFullYear().toString();
    const month = ((date.getMonth()+1 > 9) ? (date.getMonth()+1).toString() : ("0"+(date.getMonth()+1)));
    const day = ((date.getDate() > 9) ? date.getDate().toString() : ("0"+date.getDate()));
    const hours = ((date.getHours() > 9) ? date.getHours().toString() : ("0"+date.getHours()));
    const minutes = ((date.getMinutes() > 9) ? date.getMinutes().toString() : ("0"+date.getMinutes()));
    const seconds = ((date.getSeconds() > 9) ? date.getSeconds().toString() : ("0"+date.getSeconds()));

    saveAs(blob, `${factory.name}_${year}-${month}-${day}_${hours}-${minutes}-${seconds}.json`);
  }

  async function saveToCloud() {
    const f = generateCurrentSavefile(factory);
    if (user) {
      const docRef = await addDoc(collection(props.firestore, "factories"), {
        ...f,
        uid: user.uid,
      });
      dispatch(setStateAndId({id: docRef.id, type: "cloud"}));
      navigate("/factory/"+docRef.id);
    }
  }

  useEffect(() => {
    loadFactoryById();
    document.title = factory.name + " - Cogler Factory Modeler";
  });

  const cloudStatusIcon = factory.status === "synced to cloud" ? mdiCloudCheck : factory.status === "syncing" ? mdiCloudSyncOutline : mdiCloudOffOutline;

  const facDir = factory.type === "file" ? "file" : factory.id;

  const nav = isLoaded  ? <NavBarMenu>
                            <NavBarMenuLink display="FactoryPlanner" target={"/factory/"+facDir}/>
                            <NavBarMenuLink display="Items" target={"/factory/"+facDir+"/items"}/>
                            <NavBarMenuLink display="Buildings" target={"/factory/"+facDir+"/buildings"}/>
                            <NavBarMenuLink display="Tech Tree" target={"/factory/"+facDir+"/techtree"}/>
                            <ViewMenu/>
                          </NavBarMenu>
                        : <></>;

  return (
    <div className="FactoryPlanner">
      <NavBarContent className="FactoryPlanner-navbar">
        <div className="FactoryPlanner-navbar-overview">
          <div className="FactoryPlanner-navbar-name">
            <FactoryName firestore={props.firestore}/>
          </div>
          <div className="FactoryPlanner-navbar-status">
            <Icon size={0.8} path={cloudStatusIcon}/>
            {factory.status} {isLoaded && factory.type === "file" && user ? <Button text="Save to cloud" icon={mdiCloudUpload} onClick={saveToCloud}/> : ""}
            {isLoaded ? <Button text="Save as file" icon={mdiDownload} onClick={saveFactory}/> : ""}
          </div>
        </div>
        {nav}
      </NavBarContent>
      <div className="FactoryPlanner-space">
        <Outlet/>
      </div>
    </div>
  );
}

export default FactoryPlannerPage;