// external:
import { useState, useContext, useEffect } from "react";
import { Firestore } from "firebase/firestore";
// internal:
import { useAppDispatch } from "../../../../../../Redux/hooks";
import { addConnection, FactoryConnectionParameters, syncWithServer } from "../../../../../../Redux/FactorySlice";
import { FactoryElementSocket } from "../../../../../../Model/FactoryElement";
import { ItemAmount } from "../../../../../../Model/Item";
import { ID } from '../../../../../../Model/ID';
import { MouseContext } from "./../MouseContext";
import { elementIoRegEx, isConnectionValid } from "./../Helpers";
import Socket from "../Socket/Socket";
//styling:
import './FactoryElementSocketDraw.scss';

function socketTooltip(
  velocity: ItemAmount,
  efficiency: number
): string {
  return JSON.stringify({
    velocity: velocity,
    efficiency: efficiency
  });
}

export default function FactoryElementSocketDraw(props: {
  socket: FactoryElementSocket;
  setDragged: (id: ID | null) => void;
  setMouseOver: (id: ID | null) => void;
  firestore: Firestore;
}) {
  const mouse = useContext(MouseContext);
  const [status, setStatus] = useState("");
  const [tooltip, setTooltip] = useState("");
  const dispatch = useAppDispatch();

  function addConn(dispatch: any, link1: string, link2: string, firestore: Firestore){
    if(isConnectionValid(link1, link2)) {
      const match1 = link1.match(elementIoRegEx);
      const match2 = link2.match(elementIoRegEx);
  
      let start = match1;
      let end = match2;
  
      if (match1?.groups?.type === "I") {
        start = match2;
        end = match1;
      }
  
      const connection: FactoryConnectionParameters = {
        start: {
          element: String(start?.groups?.element),
          type: "O",
          socket: Number(start?.groups?.conn)
        },
        end: {
          element: String(end?.groups?.element),
          type: "I",
          socket: Number(end?.groups?.conn)
        }
      }
  
      dispatch(syncWithServer(addConnection(connection), firestore));
    }
  }

  const onMouseDown = (e: any) => {
    props.setDragged(props.socket.id);
  };

  const onMouseEnter = (e: any) => {
    props.setMouseOver(props.socket.id);
  };

  const onMouseLeave = (e: any) => {
    props.setMouseOver(null);
    setStatus("");
  };

  const onMouseUp = (e: any) => {
    if (mouse.draggedObject && isConnectionValid(mouse.draggedObject, props.socket.id))
      addConn(dispatch, mouse.draggedObject, props.socket.id, props.firestore);
    setStatus("");
    setTooltip(socketTooltip(props.socket.velocity, props.socket.owner.efficiency));
  };

  useEffect(() => {
    if(mouse.mouseOver && mouse.draggedObject && mouse.mouseOver !== mouse.draggedObject) {
      if(mouse.draggedObject === props.socket.id || mouse.mouseOver === props.socket.id) {
        if(isConnectionValid(mouse.draggedObject, mouse.mouseOver)) {
          setStatus("Status-ok");
          setTooltip("");
        } else {
          setStatus("Status-nok");
          setTooltip("");
        }
      } else {
        setStatus("");
        setTooltip(socketTooltip(props.socket.velocity, props.socket.owner.efficiency));
      }
    } else {
      setStatus("");
      setTooltip(socketTooltip(props.socket.velocity, props.socket.owner.efficiency));
    }
  }, [mouse.mouseOver, mouse.draggedObject, props.socket.id, props.socket.owner.efficiency, props.socket.velocity]);

  const isInput = () => props.socket.type === "input";

  const svgPos = { x: 0, y: props.socket.number*22 };

  const tooltipType = isInput() 
    ? "socketInputTooltip" : "socketOutputTooltip";

  return(
    <Socket
      position={svgPos}
      item={props.socket.velocity.item}
      isInput={isInput()}
      dataTip={tooltip}
      dataFor={tooltipType}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseUp={onMouseUp}
      status={status}
    />
  );
}