import { useNavigate } from "react-router-dom";
import NavBarMenuItem from "./NavBarMenuItem";
// styling:
import './NavBarMenuLink.scss';

export default function NavBarMenuLink(props: {
  display: any,
  target: string,
}) {
  const navigate = useNavigate();
  const action = () => {
    navigate(props.target);
  }
  return (
    <NavBarMenuItem display={props.display} action={action} className="NavBarMenuLink"/>
  );
}