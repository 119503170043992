import SaveFormat from "./SaveFormat";

export default class Converter {
  inputVersion: number = 0;
  outputVersion: number = 1;

  convertSave(save: SaveFormat): SaveFormat {
    return save;
  }

  convert(save: SaveFormat): SaveFormat {
    if (save.version === this.inputVersion) {
      save = this.convertSave(save);
      save.version = this.outputVersion;
    }
    return save;
  }
}