// styling
import './LoadingSpinner.scss';

export default function LoadingSpinner() {
  return (
    <div className="LoadingSpinner">
      <svg className="LoadingSpinner-svg" viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
      </svg>
    </div>
  );
}