// external:
import classNames from "classnames";
// internal:
import { SvgAtlasImage } from "../../../../../../Components/ItemImage/ItemImage";
//styling:
import './Socket.scss';

export default function Socket(props: {
  position: {x: number, y: number};
  dataTip?: string;
  dataFor?: string;
  item: string;
  isInput: boolean;
  onMouseDown?: (e: any) => void;
  onMouseEnter?: (e: any) => void;
  onMouseLeave?: (e: any) => void;
  onMouseUp?: (e: any) => void;
  status?: string;
  className?: string;
}) {
  const circleOffset = props.isInput
    ? { x: 5, y: 11 } : { x: 25, y: 11 };
  const iconOffset = props.isInput
    ? { x: 11, y: 1 } : { x: 2, y: 1 };
  const capsuleOffset = props.isInput
    ? { x: 2, y: 0 } : { x: 0, y: 0 };

  return (
    <svg
      className={classNames("Socket", props.className, props.status)}
      x={props.position.x}
      y={props.position.y}
      onMouseDown={props.onMouseDown}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onMouseUp={props.onMouseUp}
    >
      <rect
        className={classNames("Socket-capsule", props.status)}
        x={capsuleOffset.x}
        y={capsuleOffset.y}
        width="28"
        height="20"
        rx="6"
      />
      <circle
        cx={circleOffset.x}
        cy={circleOffset.y}
        r={2}
        className={classNames("Socket-io-circle", props.status)}
      />
      <SvgAtlasImage
        y={iconOffset.y}
        x={iconOffset.x}
        name={props.item}
        size={18}
        className="Socket-io-icon"
      />
      <rect
        className="Socket-hitbox"
        x={capsuleOffset.x}
        y={capsuleOffset.y}
        width="28"
        height="20"
        data-tip={props.dataTip}
        data-for={props.dataFor}
        fill="transparent"
      />
    </svg>
  );
}