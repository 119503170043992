import { SatisfactoryMilestone, SatisfactoryTier } from "../../../../Redux/FactorySlice";
import SatisfactoryMilestoneDraw from "../SatisfactoryMilestoneDraw/SatisfactoryMilestoneDraw";
// styling:
import './SatisfactoryTierDraw.scss'

export default function SatisfactoryTierDraw(props: {
  tier: SatisfactoryTier;
  selectedTier: SatisfactoryTier;
  selectedMilestone: SatisfactoryMilestone;
  selectTierAndMilestone: (t: SatisfactoryTier, m: SatisfactoryMilestone) => void;
}) {
  function selectMilestone(sm: SatisfactoryMilestone) {
    props.selectTierAndMilestone(props.tier, sm);
  }

  const milestonesDraw = props.tier.milestones.map((sm: SatisfactoryMilestone) => 
    <SatisfactoryMilestoneDraw milestone={sm} key={sm.name} selectedMilestone={props.selectedMilestone} selectMilestone={selectMilestone}/>
  );

  return (
    <div className="SatisfactoryTierDraw">
      <div className="SatisfactoryTierDraw-title">
        {props.tier.name}
      </div>
      <div className="SatisfactoryTierDraw-milestones">
        {milestonesDraw}
      </div>
    </div>
  );
}