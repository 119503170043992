// external:
import classNames from "classnames";
import { Outlet } from "react-router-dom";

function BuildingsPage() {
  return(
    <div className={classNames("BuildingsPage", "SinglePage")}>
      <Outlet/>
    </div>
  );
}

export default BuildingsPage;