import Converter from "./Converter";
import V1toV2 from "./Converters/V1toV2";
import SaveFormat from "./SaveFormat";
import { CurrentFormat, currentVersion } from "./Current";
import V2toV3 from "./Converters/V2toV3";

export default function convertToCurrentVersion(save: SaveFormat): CurrentFormat {
  const converters: Converter[] = [
    new V1toV2(),
    new V2toV3(),
  ];

  const findConverter = (c: Converter) => c.inputVersion === save.version

  while (save.version !== currentVersion) {
    const converter = converters.find(findConverter); 
    if (converter)
      save = converter.convert(save);
  }

  return (save as CurrentFormat);
}