// internal:
import { ItemAmount } from "../../Model/Item";
import AtlasImage from "../ItemImage/ItemImage";
// styling:
import './ItemCost.scss';

export default function ItemCost(props: {
  ia: ItemAmount;
}) {
  return (
    <div className="ItemCost">
      <div className="ItemCost-name">
        {props.ia.item}
      </div>
      <div className="ItemCost-amount">
        {props.ia.amount}
      </div>
      <div className="ItemCost-icon">
        <AtlasImage size={20} name={props.ia.item}/>
      </div>
    </div>
  );
}