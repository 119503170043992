import classNames from 'classnames';
import './PropsTable.scss';

export default function PropsTable(props: {
  children?: any;
  className?: string;
}){
  return (
    <div className={classNames("PropsTable", props.className)}>
      {props.children}
    </div>
  );
}