import Button from "../Button/Button";
// styling:
import './Prompt.scss';

export default function Prompt( props: {
  title: String,
  content: String,
  onAccepted?: () => void;
  onCanceled?: () => void;
  isVisible: boolean;
}) {
  if (props.isVisible) {
    return (
      <div className="Background-block">
        <div className="Prompt">
          <div className="Prompt-title">
            {props.title}
          </div>
          <div className="Prompt-body">
            <div className="Prompt-content">
              {props.content}
            </div>
            <div className="Prompt-buttons">
              <Button text="Ok" onClick={props.onAccepted}/>
              <Button text="Cancel" onClick={props.onCanceled}/>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}