import ReactTooltip from "react-tooltip";
// styling:
import './PipelineTooltip.scss';

export default function PipelineTooltip() {
  return (
    <ReactTooltip id='pipelineTooltip' effect='solid' place='bottom'
      getContent={(dataTip: string) => {
        if(dataTip === null)
          return;
        try {
          
          const parsed = JSON.parse(dataTip);
          const pipelines = [
            {tier: "Mk.1", tput: 300 },
            {tier: "Mk.2", tput: 600 },
          ];

          if(parsed.hasOwnProperty('item') && parsed.hasOwnProperty('amount')) {
            let isAlreadyMaxed = false;
            const pipelinesDraw = pipelines.map((v: {tier: string, tput: number}) => {
              if (!isAlreadyMaxed) {
                if (Math.ceil(parsed.amount / v.tput) <= 1)
                  isAlreadyMaxed = true;

                return (
                  <>
                    <div className="PipelineTooltip-pipelines-tier">
                      {v.tier + (isAlreadyMaxed ? "+": "")}
                    </div>
                    <div className="PipelineTooltip-pipelines-number">
                      {Math.ceil(parsed.amount / v.tput)}
                    </div>
                  </>
                );
              } else {
                return <></>;
              }
            });

            return (
              <div className="PipelineTooltip">
                Pipelines needed:
                <div className="PipelineTooltip-pipelines">
                  {pipelinesDraw}
                </div>
              </div>
            );
          }
        } catch (error) {
          return(<div>Pipeline tooltip: {dataTip}</div>);
        }
      }}
    />
  );
}