import classNames from "classnames";
// styling:
import './NavBarMenuItem.scss';

export default function NavBarMenuItem(props: {
  display: any;
  children?: any;
  action?: () => void;
  className?: string;
}) {
  return (
    <div className={classNames("NavBarMenuItem", "noselect", props.className)}>
      <div className="NavBarMenuItem-name" onClick={props.action}>{props.display}</div>
      {props.children ? <div className="NavBarMenuItem-children">{props.children}</div> : <></>}
    </div>
  );
}