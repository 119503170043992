// external:
import Icon from '@mdi/react';
import classNames from 'classnames';
// styling:
import './Button.scss';

export default function Button(props: {
  text: string;
  icon?: string;
  className?: string;
  onClick?: () => void;
  disabled?: any;
}) {
  return (
    <button className={classNames("Button", props.className)} onClick={props.onClick} disabled={props.disabled}>
      <div className="Button-content">
        {props.icon ? <span className="Button-icon">
          <Icon path={props.icon} size={0.8}/>
        </span> : ""}
        <div className="Button-text">
          {props.text}
        </div>
      </div>
    </button>
  )
}