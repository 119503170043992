// external:
import { useEffect } from 'react';
import { User } from 'firebase/auth';
import { useCollection } from 'react-firebase-hooks/firestore';
import { collection, doc, deleteDoc, Firestore, where, query } from 'firebase/firestore';
// internal:
import FactoryListElement from './FactoryListElement';
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { RootState } from "../../Redux/store";
import { FactoryListItem, setFactoriesList } from '../../Redux/FactoriesSlice';
// styling:
import './FactoriesList.scss';

function FactoriesList(props: {
  firestore: Firestore,
  user: User,
  showPrompt: (title: string, content: string, onAccepted: () => void) => void,
}) {
  const factoriesList = useAppSelector((state: RootState) => state.factories.factories);

  const dispatch = useAppDispatch();
  const factoriesQuery = query(collection(props.firestore, 'factories'), where('uid', '==', props.user.uid))
  const [factories] = useCollection(factoriesQuery);

  useEffect(() => {
    if (factories) {
      const facList: FactoryListItem[] = [];
      factories.forEach((doc: any) => {
        facList.push({
          name: doc.data().name,
          template: doc.data().template,
          id: doc.id,
          factory: doc.data().factory,
          version: 1
        });
      });
      dispatch(setFactoriesList(facList));
    }
  }, [factories, dispatch])

  document.title = "Cogler Factory Modeler";

  async function deleteFactory(noteId: any) {
    await deleteDoc(doc(props.firestore, "factories", noteId));
  }

  function deleteFactoryPrompt(factoryId: any) {
    props.showPrompt("Delete Factory", "Are you sure you want to delete this factory? It's permament and deleted factory cannot be restored.", () => deleteFactory(factoryId));
  }

  return (
    <div className="FactoriesList">
      <div className="FactoriesList-list">
        { 
          factoriesList.map((f: any) => (
            <FactoryListElement key={f.id} factory={f} deleteFactory={deleteFactoryPrompt} firestore={props.firestore}/>
          ))
        }
      </div>
    </div>
  );
}

export default FactoriesList;