// external:
import { useState, useRef, useEffect } from "react";
import classNames from "classnames";
//styling:
import './TextInRect.scss';

export default function TextInRect(props: {
  text: string;
  position: {x: number, y: number}
  className?: string,
  dataTip?: string,
  dataFor?: string,
}) {
  const [textSize, setTextSize] = useState<{x: number, y: number}>({ x: 0, y: 0 });

  const textRef = useRef<SVGTextElement>(null);
  const text = <text
    ref={textRef}
    x={props.position.x}
    y={props.position.y}
    textAnchor="middle"
    alignmentBaseline="central"
    className="TextInRect-text"
  >
    {props.text}
  </text>;

  useEffect(() => {
    if (textRef.current) {
      const bb = textRef.current.getBBox();
      setTextSize({ x: bb.width, y: bb.height });
    }
  }, [setTextSize, props.text]);

  const rect = <rect className="TextInRect-rect" rx={4}
    x={props.position.x - 2 - (textSize.x / 2)}
    y={props.position.y - 2 - (textSize.y / 2)}
    width={textSize.x + 4}
    height={textSize.y + 4} />;

  return (
    <g className={classNames("TextInRect", props.className)}
      data-tip={props.dataTip}
      data-for={props.dataFor}
    >
      {rect}
      {text}
    </g>
  );
}