// internal:
import { useAppSelector } from '../../../Redux/hooks';
import { Building } from '../../../Model/Building';
import { RootState } from '../../../Redux/store';
import CardsBrowser from '../../../Components/CardsBrowser/CardsBrowser';
import BuildingCard from '../../../Components/Cards/BuildingCard/BuildingCard';
import EditorUI from '../../../Components/EditorUI/EditorUI';
import SingleBuildingPage from '../SingleBuildingPage/SingleBuildingPage';
import { useState } from 'react';
import { SectionConfig } from '../../../Components/EditorUI/EditorComponentFactory/EditorComponentFactory';

function BuildingsListPage() {

  const [selectedBuilding, setSelectedBuilding] = useState("assembler"); 

  const buildings = useAppSelector((state: RootState) => state.factory.buildings);
  const buildingsDraw = buildings.map(
    (i: Building) => <BuildingCard key={i.id} building={i} select={setSelectedBuilding} selected={selectedBuilding}/>
  );


  const primitives = [
    {
      id: "cards", 
      name: "Cards",
      element: <CardsBrowser>{buildingsDraw}</CardsBrowser>
    },
    {
      id: "sidepanel",
      name: "Editor Side Panel",
      element: <SingleBuildingPage building={selectedBuilding}/>
    }
  ];

  const [sections] = useState<SectionConfig[]>([
    {
      id:"root",
      type:"horizontal",
      elements: [
        {
          id: "cards",
          weight: 0.5,
          minSize:"50%"
        }, {
          id: "sidepanel",
          weight: 0.5,
          minSize: "350px",
        }
      ]
    }
  ]);



  return <EditorUI primitives={primitives} initialSections={sections}/>
}

export default BuildingsListPage;