// external:
import classNames from 'classnames';
// internal:
import { Item } from '../../../Model/Item';
import AtlasImage from '../../ItemImage/ItemImage';
import Card from '../Card';
// styling:
import './ItemCard.scss';

export default function ItemCard(props: {
  item: Item;
  selected?: string;
  select?: (i: string) => void;
}) {
  function onClick() {
    props.select?.(props.item.id);
  }

  return (
      <Card className={classNames("Item", "Card-twosides", (props.selected === props.item.id) ? "selected" : "")} onClick={onClick}>
        <div className="Card-icon">
          <AtlasImage 
            name={props.item.name}
            size={200}
          />
        </div>
        <div className="Card-description">
          <div className="Card-name">
            {props.item.name}
          </div>
          <div className="Item-category">
            {props.item.category}
          </div>
        </div>
      </Card>
  );
}