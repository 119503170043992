// external:
import classNames from "classnames";
// internal:
import { Recipe } from "../../../Model/Recipe";
import { ItemAmount } from "../../../Model/Item";
import { RootState } from "../../../Redux/store";
import { useAppSelector } from "../../../Redux/hooks";
import AtlasImage from "../../ItemImage/ItemImage";
import Card from "../Card";
// styling: 
import './RecipeCard.scss';

function ItemAmountDraw(props: {
  i: ItemAmount
  execSecs: number;
}){
  const isPerMinuteModeEnabled = useAppSelector((state: RootState) => state.settings.editor.assetBrowser.isPerMinuteModeEnabled);

  const item = useAppSelector((state: RootState) => 
    state.factory.items.find(item => item.name === props.i.item)
  );
  const itemDraw = item ? 
      <AtlasImage
        className="ItemAmount-img"
        name={item?.name}
        size={30}
      />
    :
      props.i.item

  return (
    <div className="Item Amount">
      <div className="Amount">
        {isPerMinuteModeEnabled ? (props.i.amount * (60 / props.execSecs)).toFixed(1) + "/m" : props.i.amount}
      </div>
      <div className="Item">
        {itemDraw}
      </div>
    </div>
  );
}

export default function RecipeCard(props: {
  recipe: Recipe,
  draggable?: boolean,
}) {
  const isPerMinuteModeEnabled = useAppSelector((state: RootState) => state.settings.editor.assetBrowser.isPerMinuteModeEnabled);

  const executor = useAppSelector((state: RootState) => 
    state.factory.buildings.find(building => building.name === props.recipe.executor) 
  );

  const ingredientsDraw = props.recipe.ingredients.map((i: ItemAmount, index: number) => 
    <ItemAmountDraw key={"i"+props.recipe.name+index} i={i} execSecs={props.recipe.executionTimeInSec}/>
  );

  const productsDraw = props.recipe.products.map((i: ItemAmount, index: number) => 
    <ItemAmountDraw key={"p"+props.recipe.name+index} i={i} execSecs={props.recipe.executionTimeInSec}/>
  );

  const alt = props.recipe.isAlt ? <span className="Alternative">ALT</span> : <></>;
  const event = props.recipe.event ? <span className="Event">{props.recipe.event}</span> : <></>;
  if(executor) 
    return (
        <Card className={classNames("Recipe", "noselect")}
          draggable={props.draggable}
          dataValue={props.recipe.id}
        >
          <div className={classNames("Title", "noselect")}>
            {props.recipe.name} {alt} {event}
          </div>
          <div className="Content">
            <div className="Ingredients">
              {ingredientsDraw}
            </div>
            <div className={classNames("Executor", "Building")}>
              <AtlasImage
                className="Executor-img"
                name={executor.name}
                size={140}
              />
              {isPerMinuteModeEnabled ? "" : `${props.recipe.executionTimeInSec} sec.`}
            </div>
            <div className="Products">
              {productsDraw}
            </div>
          </div>
        </Card>
    );
  else return <></>;
}