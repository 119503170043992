// external:
import { Auth, signInWithEmailAndPassword } from "firebase/auth";
import { useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { mdiLogout, mdiLogin, mdiAccountPlus } from '@mdi/js';
// internal:
import Button from "../Button/Button";

export default function UserPanel(props: {
  auth: Auth
}){
  const [user] = useAuthState(props.auth);
  const loginRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const signOut = () => {
    props.auth.signOut();
  };

  const signIn = () => {
    if(loginRef && loginRef.current && passwordRef && passwordRef.current) {
      signInWithEmailAndPassword(props.auth, loginRef.current.value, passwordRef.current.value);
    }
  };

  const onPasswordInput = (e: any) => {
    if (e.key === "Enter") {
      signIn();
    }
  };

  if(user) {
    return (
      <div className="NavBar-UserPanel">
        <div>Logged in as {props.auth.currentUser?.email}</div>
        <Button onClick={signOut} text="Log out" icon={mdiLogout}/>
      </div>
    );
  } else {
    return (
      <div className="NavBar-UserPanel">
        <div><input ref={loginRef} type="text"/></div>
        <div><input ref={passwordRef} type="password" onKeyDown={onPasswordInput}/></div>
        <div>
          <Button onClick={signIn} text="Sign in" icon={mdiLogin}/>
          <Button text="Sign up" icon={mdiAccountPlus} disabled/>
        </div>
      </div>
    );
  }
}