import { ElementConfig } from '../EditorComponentFactory/EditorComponentFactory';
import './EditorCard.scss';

export default function EditorCard(props: {
  children?: any;
  config: ElementConfig;
}) {
  var style = { "--size": ((props.config.weight ?? 1) *100)+"%", "--min-size": props.config.minSize, "--max-size": (props.config.maxSize ?? "100%")} as React.CSSProperties;

  return (
    <div className="EditorCard" style={style}>
      {props.children}
    </div>
  );
}