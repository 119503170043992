export function calcOutputX(elementPos: {x: number, y: number}, outputNum: number): number {
  return elementPos.x + 195;
}

export function calcConnectionY(elementPos: {x: number, y: number}, outputNum: number): number {
  return elementPos.y + 26 + 11 + (22*outputNum);
}

export function calcInputX(elementPos: {x: number, y: number}, outputNum: number): number {
  return elementPos.x + 5;
}

export function calcInputCapsuleX(elementPos: {x: number, y: number}, outputNum: number): number {
  return elementPos.x;
}

export function calcCapsuleY(elementPos: {x: number, y: number}, outputNum: number): number {
  return elementPos.y + 20 + 6 + outputNum*22;
}

export function calcOutputCapsuleX(elementPos: {x: number, y: number}, outputNum: number): number {
  return elementPos.x + 170;
}

export function addPositions(pos1: {x: number, y: number}, pos2: {x: number, y: number}) {
  return {
    x: pos1.x + pos2.x,
    y: pos1.y + pos2.y
  }
}

export const elementIoRegEx = /(?<element>fe[0-9]+)#(?<type>O|I)(?<conn>[0-9]+)_(?<item>[A-Za-z ]+)/;

export function isConnectionValid(link1: string, link2: string): boolean {
  const match1 = link1.match(elementIoRegEx);
  const match2 = link2.match(elementIoRegEx);

  return Boolean(match1 && match2 &&
    match1.groups && match2.groups &&
    match1.groups.element && match2.groups.element &&
    match1.groups.type && match2.groups.type &&
    match1.groups.item && match2.groups.item &&
    match1.groups.conn && match2.groups.conn &&
    match1.groups.element !== match2.groups.element &&
    match1.groups.type !== match2.groups.type &&
    match1.groups.item === match2.groups.item);
}