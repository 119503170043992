import { FactoryState, getRecipeSocketVelocity } from '../Redux/FactorySlice';
import { FactoryConnection } from './FactoryConnection';
import { ID } from './ID';
import { ItemAmount } from './Item';
import { Recipe } from './Recipe';
import { FactoryElementParams2 } from './SaveHandler/Versions/V3';

export interface Position {
  x: number,
  y: number;
}

export interface FactoryElementParameters {
  position: Position;
  recipeId: ID;
  number: number;
}

export interface FactoryElementSocket {
  id: ID;
  number: number,
  velocity: ItemAmount;
  type: "input" | "output",
  owner: FactoryElement;
}

export default class FactoryElement {
  position: Position = {x: 0, y: 0};
  number: number = 1;
  id: ID = "";
  recipeId: ID = "";
  inputSockets: FactoryElementSocket[] = [];
  outputSockets: FactoryElementSocket[] = [];
  efficiency: number = 0;

  constructor(p: FactoryElementParams2) {
    this.position = p.position;
    this.number = p.number;
    this.id = p.id;
    this.recipeId = p.recipeId;
    this.inputSockets = p.inputSockets;
    this.outputSockets = p.outputSockets;
    this.efficiency = p.efficiency;
  }

  getRecipe(state: FactoryState): Recipe {
    return state.recipes.find((r: Recipe) => r.id === this.recipeId)!;
  }

  getInputs(state: FactoryState): FactoryConnection[] {
    let inputs: FactoryConnection[] = [];
    state.connections.forEach((c: FactoryConnection) => {
      if (c.end.element === this.id)
        inputs.push(c);
    });
  
    return inputs;
  }
  
  getOutputs(state: FactoryState): FactoryConnection[] {
    let outputs: FactoryConnection[] = [];
    state.connections.forEach((c: FactoryConnection) => {
      if (c.start.element === this.id)
        outputs.push(c);
    });
  
    return outputs;
  }

  serialize(): FactoryElementParams2 {
    return {
      position: this.position,
      number: this.number,
      id: this.id,
      recipeId: this.recipeId,
      inputSockets: this.inputSockets,
      outputSockets: this.outputSockets,
      efficiency: this.efficiency
    };
  }

  getEfficiencyCappedByInputs(state: FactoryState): number {
    let efficiency = 1;
    const recipe = this.getRecipe(state);
    const inputs = this.getInputs(state);
    if (inputs.length === recipe.ingredients.length) {
      inputs.forEach((conn: FactoryConnection) => {
        let inputSocketAmount = getRecipeSocketVelocity(recipe, conn.end.socket, "i") * this.number;
  
        const connectionContent = conn.getContent(state);
        efficiency = Math.min(efficiency, connectionContent.amount / inputSocketAmount);
      });
      return efficiency;
    }
    return 0;
  }
  
  getEfficiencyCappedByOutputs(state: FactoryState): number {
    let efficiency = 1;
    const recipe = this.getRecipe(state);
    const outputs = this.getOutputs(state);
    if (outputs.length === 0 || outputs.length === recipe.products.length) {
      outputs.forEach((conn: FactoryConnection) => {
        let outputSocketAmount = getRecipeSocketVelocity(recipe, conn.start.socket, "o") * this.number;
  
        const connectionConsumption = conn.getConsumption(state);
        efficiency = Math.min(efficiency, connectionConsumption.amount / outputSocketAmount);
      })
      return efficiency;
    }
    return 0;
  }
}