// external:
import classNames from 'classnames';
// internal:
import { Building } from '../../../Model/Building';
import AtlasImage from '../../ItemImage/ItemImage';
import Card from '../Card';
// styling:
import './BuildingCard.scss';

export default function BuildingCard(props: {
  building: Building;
  select?: (b: string) => void;
  selected?: string;
}) {
  function onClick() {
    props.select?.(props.building.id);
  }

  return (
    <Card className={classNames("Building", "Card-twosides", (props.selected === props.building.id) ? "selected" : "")} onClick={onClick}>
      <div className="Card-icon">
        <AtlasImage
          className="Executor-img"
          name={props.building.name}
          size={200}
        />
      </div>
      <div className="Card-description">
        <div className="Card-name">
          {props.building.name}
        </div>
        <div className="Building-category">
          {props.building.typeId}
        </div>
      </div>
    </Card>
  );
}