import ReactTooltip from "react-tooltip";

export default function SimpleTooltip() {
  return (
    <ReactTooltip id='simpleTooltip' effect='solid' place='top'
      getContent={(dataTip: string) => {
        if(dataTip === null)
          return;
        try {
          const parsed = JSON.parse(dataTip);
          if (parsed.hasOwnProperty('title') && parsed.hasOwnProperty('content')) {
            return(
              <div>
                <div>
                  <strong>{parsed.title}</strong>
                </div>
                <div>
                  {parsed.content}
                </div>
              </div>
            );
          }
        } catch (error) {
          return(
            <div>
              {dataTip}
            </div>
          );
        }
      }}
    />
  );
}