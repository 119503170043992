function AtlasImage(props: {
  name: string;
  size?: number;
  className?: string;
}) {
  const atlasData = require('./../../Data/Satisfactory-ItemsAtlas.json');

  const nameToFind = props.name.replaceAll(" ", "_");
  const item = atlasData.atlas.items.find((item: any) => nameToFind === item.name);
  if(item) {
    let tw = atlasData.atlas.tileSizeX;
    let th = atlasData.atlas.tileSizeY;
    let tx = item.x;
    let ty = item.y;
    let src = '/Icons/IconsAtlas.png';

    let bgSizeX = (atlasData.atlas.tileSizeX+atlasData.atlas.gap)*atlasData.atlas.sizeX;
    let bgSizeY = (atlasData.atlas.tileSizeY+atlasData.atlas.gap)*atlasData.atlas.sizeY;

    let gap = atlasData.atlas.gap;

    if(props.size) {
      const scale = props.size/atlasData.atlas.tileSizeX;
      tw *= scale;
      th *= scale;
      bgSizeX *= scale;
      bgSizeY *= scale;
      gap *= scale;
    }

    let x = (tx*(tw+gap));
    let y = (ty*(th+gap));

    return (
      <div
        className={"ItemImage "+props.className}
        style={{
          width: tw+"px",
          height: th+"px",
          backgroundImage: "url(" + src + ")",
          backgroundPosition: '-' + x + 'px -' + y + 'px',
          backgroundSize: bgSizeX + "px " + bgSizeY + "px"
        }}
      />
    );
  } else {
    return <></>;
  }
}

export function SvgAtlasImage(props: {
  name: string,
  size?: number,
  className?: string,
  x: number,
  y: number,
}) {
  const atlasData = require('./../../Data/Satisfactory-ItemsAtlas.json');

  const nameToFind = props.name.replaceAll(" ", "_");
  const item = atlasData.atlas.items.find((item: any) => nameToFind === item.name);
  if(item && props.size) {
    let tw = atlasData.atlas.tileSizeX;
    let th = atlasData.atlas.tileSizeY;
    let tx = item.x;
    let ty = item.y;
    let src = '/Icons/IconsAtlas.png';

    let bgSizeX = (atlasData.atlas.tileSizeX+atlasData.atlas.gap)*atlasData.atlas.sizeX;
    let bgSizeY = (atlasData.atlas.tileSizeY+atlasData.atlas.gap)*atlasData.atlas.sizeY;

    let gap = atlasData.atlas.gap;

    let x = (tx*(tw+gap));
    let y = (ty*(th+gap));

    return (
      <svg
        width={props.size}
        height={props.size}
        viewBox={x+" "+y+" "+tw+" "+th}
        x={props.x}
        y={props.y}
        overflow="hidden"
      >
        <image
          className="FactoryElement-executor-icon"
          y={0}
          x={0}
          width={bgSizeX}
          height={bgSizeY}
          href={src}
        />
      </svg>
    );
  } else {
    return <></>;
  }
}

export default AtlasImage;