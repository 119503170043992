import classNames from "classnames";
// styling:
import './NavBarContent.scss';

export default function NavBarContent(props: {
  children?: any;
  className?: string;
}) {
  return (
    <div className={classNames("NavBarContent", props.className)}>
      {props.children}
    </div>
  );
}