// external:
import { Firestore } from 'firebase/firestore';
import { useRef, useState } from 'react';
import { collection, addDoc } from "firebase/firestore"; 
import { Link, useNavigate } from "react-router-dom";
import { Auth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
// internal:
import Button from "../../Components/Button/Button";
import { useAppDispatch } from '../../Redux/hooks';
import { loadFactoryData } from '../../Redux/FactorySlice';
import { GetSatisfactoryTemplate } from '../../Data/DataLoader';
// styling:
import './NewFactoryPage.scss';

export default function NewFactoryPage(props: {
  firestore: Firestore,
  auth: Auth
}) {
  const navigate = useNavigate();
  const [canCreate, setCanCreate] = useState(false);
  const dispatch = useAppDispatch();
  const [user] = useAuthState(props.auth);

  const nameRef = useRef<HTMLInputElement>(null);
  const cloudRef = useRef<HTMLInputElement>(null);
  
  const validateName = () => {
    if (nameRef && nameRef.current && nameRef.current.value.length > 0) {
      setCanCreate(true);
    } else {
      setCanCreate(false);
    }
  };

  const createFactory = async () => {
    if (nameRef && nameRef.current) {
      if (cloudRef && cloudRef.current && cloudRef.current.checked && props.auth.currentUser) {
        const docRef = await addDoc(collection(props.firestore, "factories"), {
          uid: props.auth.currentUser.uid,
          name: nameRef.current.value,
          template: "Satisfactory",
          factory: JSON.stringify({
            elements: [],
            connections: []
          }),
          version: 1
        });
        navigate("/factory/"+docRef.id);
      } else {
        const f = {
          name: nameRef.current.value,
          template: "Satisfactory",
          version: 2,
          factory: {
            elements: [],
            connections: []
          }
        }
        dispatch(loadFactoryData({template: GetSatisfactoryTemplate(), id: "file", type: "file", factory: f}));
        navigate("/factory/file");
      }
    }
  }

  return (
    <div className="NewFactoryPage">
      <div className="WholeWidth">New Factory</div>
      <div className="FieldName">Factory Name</div>
      <div className="FieldValue"><input type="text" ref={nameRef} onChange={validateName} placeholder="name cannot be empty"></input></div>
      <div className="FieldName">Template</div>
      <div className="FieldValue">Satisfactory</div>
      <div className="FieldName">Sync to cloud</div>
      <div className="FieldValue"><div><input type="checkbox" ref={cloudRef} defaultChecked={user != null} disabled={user == null}/></div>{user ? "" : <div>you need to be logged in to save factory in cloud</div>}</div>
      <div className="WholeWidth">
        <Button className="Create" text="Create" disabled={!canCreate} onClick={createFactory}/>
        <Link to="/">
          <Button text="Cancel"/>
        </Link>
      </div>
    </div>
  );
}