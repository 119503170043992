// external:
import { Outlet } from "react-router-dom";
import classNames from "classnames";

function ItemsPage() {
  return(
    <div className={classNames("ItemsPage", "SinglePage")}>
      <Outlet/>
    </div>
  );
}

export default ItemsPage;