// external:
import { useEffect, useRef, useState } from "react";
import Measure from 'react-measure';
// styling:
import './SvgCanvas.scss';

interface SvgClassProps {
  children?: any;
  svgOnMouseDown?: (e: any) => void;
  svgOnMouseMove?: (e: any) => void;
  clearDragOffset?: () => void;
  addToDragOffset?: (deltaOffset: {x: number, y: number}) => void;
  svgOnMouseUp?: (e: any, svgPos: {x: number, y: number}) => void;
  onDrop?: (e: any, pos: {x: number, y: number}) => void;
  onDragEnter?: (e: any) => void;
  onDragOver?: (e: any) => void;
}

export default function SvgCanvas(props: SvgClassProps) {
  const [isDraggingElement, setIsDraggingElement] = useState(false);
  const [isMovingViewbox, setIsMovingViewbox] = useState(false);
  const [svgSize, setSvgSize] = useState({width: 0, height: 0});
  const [svgPos, setSvgPos] = useState({x: 0, y: 0});
  const [centerPoint, setCenterPoint] = useState({x: 0, y: 0});
  const [scale, setScale] = useState(1);
  const svgRef = useRef<SVGSVGElement>(null);

  function calculateMousePosition(pageX: number, pageY: number) {
    let mousePosition = {
      x: (centerPoint.x - ((svgSize.width / 2)) / scale) + ((pageX - svgPos.x) / svgSize.width) * ((svgSize.width) / scale),
      y: (centerPoint.y - ((svgSize.height / 2)) / scale) + ((pageY - svgPos.y) / svgSize.height) * ((svgSize.height) / scale)
    };
    return mousePosition;
  }

  function viewboxMinX(): number {
    return (centerPoint.x - ((svgSize.width / 2)) / scale);
  }

  function viewBoxMinY(): number {
    return (centerPoint.y - ((svgSize.height / 2)) / scale);
  }

  function viewBoxWidth(): number {
    return ((svgSize.width) / scale);
  }

  function viewBoxHeight(): number {
    return ((svgSize.height) / scale);
  }

  function svgOnWheel(e: any) {
    setScale((previous: number) => previous * (1 - e.deltaY / 1000));
  }

  function viewBox(): string {
    return (
      viewboxMinX() + ' ' +
      viewBoxMinY() + ' ' +
      viewBoxWidth() + ' ' +
      viewBoxHeight()
    );
  }

  const backgroundOnMouseDown = (e: any) => {
    e.preventDefault();
    if (e.buttons === 2) {
      setIsMovingViewbox(true);
    }
  };

  const svgOnMouseUp = (e: any) => {
    if (e.buttons !== 2) {
      setIsMovingViewbox(false);
    }
    if (e.buttons !== 1) {
      props.clearDragOffset?.();
      setIsDraggingElement(false);
    }
    const pos = calculateMousePosition(e.pageX, e.pageY);
    props.svgOnMouseUp?.(e, pos);
  }

  const svgOnMouseMove = (e: any) => {
    if (isMovingViewbox) {
      const preMousePos = calculateMousePosition(e.pageX - e.movementX, e.pageY - e.movementY);
      const postMousePos = calculateMousePosition(e.pageX, e.pageY);
      const deltaPos = {x: postMousePos.x - preMousePos.x, y: postMousePos.y - preMousePos.y};
      setCenterPoint({x: centerPoint.x - deltaPos.x ,y: centerPoint.y - deltaPos.y});
    } 
    if (isDraggingElement){
      const preMousePos = calculateMousePosition(e.pageX - e.movementX, e.pageY - e.movementY);
      const postMousePos = calculateMousePosition(e.pageX, e.pageY);

      props.addToDragOffset?.({x: postMousePos.x - preMousePos.x, y: postMousePos.y - preMousePos.y});
    }
    props.svgOnMouseMove?.(e);
  }

  function svgOnClick(e: any) {
    e.preventDefault();
  }

  function svgOnMouseDown(e: any) {
    if (e.buttons === 1) {
      setIsDraggingElement(true);
    }
    props.svgOnMouseDown?.(e);
  }

  function svgOnDrop(e: any) {
    const pos = calculateMousePosition(e.pageX, e.pageY);
    props.onDrop?.(e, pos);
  }

  useEffect(() => {
    setTimeout(() => {
      if (svgRef.current) {
        const box = svgRef.current.getBoundingClientRect();
        if (box) {
          setSvgSize({height: box.height || 0, width: box.width || 0});
          setSvgPos({x: box.left || 0, y: box.top || 0});
        }
      }
    }, 1000);  
  }, []);

/*   const theX = <>
    <rect
      x={-1000} y={-0.5} width={2000} height={1}
    />
    <rect
      x={-0.5} y={-1000} width={1} height={2000}
    />
  </> */

  return (
    <Measure
      bounds
      onResize={contentRect => {
        setSvgSize({height: contentRect.bounds?.height || 0, width: contentRect.bounds?.width || 0});
        setSvgPos({x: contentRect.bounds?.left || 0, y: contentRect.bounds?.top || 0});
      }}
    >
      {({ measureRef }) => (
        <div className="SvgCanvas-div"
        ref={measureRef}>
          <svg className="SvgCanvas-svg"
            onMouseUp={svgOnMouseUp}
            onMouseMove={svgOnMouseMove}
            viewBox={viewBox()}
            onWheel={svgOnWheel}
            onClick={svgOnClick}
            onContextMenu={svgOnClick}
            onMouseDown={svgOnMouseDown}
            onDragEnter={props.onDragEnter}
            onDragOver={props.onDragOver}
            onDrop={svgOnDrop}
            ref={svgRef}
          >
            <rect className="SvgCanvas-background"
              x={viewboxMinX()} y={viewBoxMinY()}
              width={viewBoxWidth()} height={viewBoxHeight()}
              onMouseDown={backgroundOnMouseDown}
              onContextMenu={(e: any) => {e.preventDefault();}}
            />
            {props.children}
          </svg>
        </div>
      )}
    </Measure>
  );
}