// external:
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// internal:
import type { RootState } from './store';

export interface FactoryListItem {
  id: string;
  name: string;
  template: string;
  factory: string;
  version: 1;
};

interface FactoriesState {
  factories: FactoryListItem[];
}

const initialState: FactoriesState = {
  factories: [],
};

export const factoriesSlice = createSlice({
  name: 'factories',
  initialState,
  reducers: {
    setFactoriesList: (state, action: PayloadAction<FactoryListItem[]>) => {
      state.factories.splice(0, state.factories.length);
      state.factories.push(...action.payload);
    }
  }
});

export const { setFactoriesList } = factoriesSlice.actions;

export const selectFactories = (state: RootState) => state.factories;

export default factoriesSlice.reducer;