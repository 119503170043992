// external:
import React, { useContext } from "react";
import classNames from "classnames";
// internal:
import { RootState } from "../../../../../../Redux/store";
import FactoryElement from "../../../../../../Model/FactoryElement";
import { Recipe } from '../../../../../../Model/Recipe';
import { ID } from '../../../../../../Model/ID';
import LinkPath from "./../LinkPath/LinkPath";
import { useAppSelector } from "../../../../../../Redux/hooks";
import { calcInputCapsuleX, calcCapsuleY, calcOutputCapsuleX, elementIoRegEx, addPositions, isConnectionValid } from "./../Helpers";
import { MouseContext } from "../MouseContext";
import Socket from "../Socket/Socket";
//styling:
import './ConnectionAddingHelper.scss';

export default function ConnectionAddingHelper(props: {
  connectedElement: ID;
  connectionNumber: number;
  offset: {x: number, y: number};
  connectedType: string;
  connectionId: ID;
}) {
  const isAddingOutput = props.connectedType === "O";
  const mouse = useContext(MouseContext);

  const element = useAppSelector((state: RootState) => state.factory.elements.find((fe: FactoryElement) => fe.id === props.connectedElement));
  const recipe = useAppSelector((state: RootState) => state.factory.recipes.find((r: Recipe) => element ? r.id === element.recipeId : false));

  const mouseOverElement = useAppSelector((state: RootState) => state.factory.elements.find((fe: FactoryElement) => {
    if (mouse.mouseOver) {
      const matchIo = mouse.mouseOver.match(elementIoRegEx);
      if(matchIo && matchIo.groups && matchIo.groups.element) {
        return matchIo.groups.element === fe.id;
      }
    }
    return false;
  }));

  if (element && recipe) {
    const connectedItem = (isAddingOutput ? recipe.products[props.connectionNumber] : recipe.ingredients[props.connectionNumber]).item;
    let endAsOutput = false;
    let startAsInput = false;

    const existingCapsulePos = {
      x: isAddingOutput ? calcOutputCapsuleX(element.position, props.connectionNumber) : calcInputCapsuleX(element.position, props.connectionNumber),
      y: calcCapsuleY(element.position, props.connectionNumber)
    };

    let mouseCapsulePos = {
      x: existingCapsulePos.x + props.offset.x,
      y: existingCapsulePos.y + props.offset.y
    };

    const existingLinkOffset = {
      x: isAddingOutput ? 25 : 5,
      y: 11
    };

    let mouseLinkOffset = {
      x: isAddingOutput ? 5 : 25,
      y: 11
    };

    let capsuleStatus = "";
    let linkStatus = "";
    if (mouse.mouseOver){
      const matchIo = mouse.mouseOver.match(elementIoRegEx);
      if (matchIo && matchIo.groups && matchIo.groups.element && matchIo.groups.type && matchIo.groups.conn && matchIo.groups.item) {
        capsuleStatus = "Status-hidden";
        if(mouseOverElement) {
          mouseCapsulePos = {
            x: matchIo.groups.type === "I" ? calcInputCapsuleX(mouseOverElement.position, Number(matchIo.groups.conn)) : calcOutputCapsuleX(mouseOverElement.position, Number(matchIo.groups.conn)),
            y: calcCapsuleY(mouseOverElement.position, Number(matchIo.groups.conn))
          }
          mouseLinkOffset = matchIo.groups.type === "I" ? {
            x: 5, y: 11
          } : {
            x: 25, y: 11
          }

          if(isAddingOutput && matchIo.groups.type === "O") {
            endAsOutput = true;
          }
          if(!isAddingOutput && matchIo.groups.type === "I") {
            startAsInput = true;
          }
        }

        if(mouse.mouseOver === props.connectionId) {
          linkStatus = "Status-hidden";
        } else if (isConnectionValid(mouse.mouseOver, props.connectionId)) {
          linkStatus = "Status-ok";
        } else {
          linkStatus = "Status-nok";
        }
      }
    }

    const mouseLinkPos = addPositions(mouseCapsulePos, mouseLinkOffset);
    const existingLinkPos = addPositions(existingCapsulePos, existingLinkOffset);

    const inputPos = isAddingOutput ? existingLinkPos : mouseLinkPos;
    const outputPos = isAddingOutput ? mouseLinkPos : existingLinkPos;
    
    return (
      <React.Fragment>
        <Socket
          position={mouseCapsulePos}
          item={connectedItem}
          isInput={isAddingOutput}
          className="ConnectionAddingHelper"
          status={capsuleStatus}
        />
        <LinkPath
          className={classNames("ConnectionAddingHelper", linkStatus)}
          startPos={inputPos}
          endPos={outputPos}
          endAsOutput={endAsOutput}
          startAsInput={startAsInput}
        />
      </React.Fragment>
    );
  } else {
    return <></>;
  }
}