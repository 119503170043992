// styling:
import './WelcomeMessage.scss';

export default function WelcomeMessage() {
  return (
    <div className="WelcomeMessage">
      Hello.<br/>
      This app is a helper tool for <a href="https://www.satisfactorygame.com/">Satisfactory</a> video game by <a href="https://www.coffeestainstudios.com/">Coffee Stain Studios</a>. You can create factory and save it as a file to open it later on.<br/>
      Accounts that allow storing your factory in cloud are currently limited.<br/><br/>
      Example screen of simple factory with calculated output and cost:<br/>
      <img src="/example.png" alt="sample screenshot"/>
    </div>
  );
}