// external:
import { useRef, useState } from "react";
import { Firestore } from "firebase/firestore";
import classNames from "classnames";
// internal:
import { useAppDispatch, useAppSelector } from "../../../../../../Redux/hooks";
import { RootState } from "../../../../../../Redux/store";
import { deleteElement, setElementNumber, setElementPosition, syncWithServer } from "../../../../../../Redux/FactorySlice";
import FactoryElement, { FactoryElementSocket } from "../../../../../../Model/FactoryElement";
import { ItemAmount } from "../../../../../../Model/Item";
import { Recipe } from '../../../../../../Model/Recipe';
import { ID } from '../../../../../../Model/ID';
import { SvgAtlasImage } from "../../../../../../Components/ItemImage/ItemImage";
import { ContextMenuData } from "../../../../../../Components/ContextMenu/ContextMenu";
import FactoryElementSocketDraw from "./../FactoryElementSocketDraw/FactoryElementSocketDraw";
//styling:
import './FactoryElementDraw.scss';

function getEfficiencyClass(efficiency: number): string {
  if(efficiency >= 0.95) {
    return "Efficiency-very-high";
  } else if (efficiency >= 0.75) {
    return "Efficiency-high";
  } else if (efficiency >= 0.5) {
    return "Efficiency-medium";
  } else if (efficiency >= 0.25) {
    return "Efficiency-low";
  } else {
    return "Efficiency-very-low";
  }
}

export default function FactoryElementDraw(props: {
  fe: FactoryElement;
  positionOffset: {x: number, y: number};
  setDragged: (id: ID | null) => void;
  setMouseOver: (id: ID | null) => void;
  isGrabbed?: any;
  firestore: Firestore;
  contextMenu: (context: ContextMenuData) => void;
}) {
  const dispatch = useAppDispatch();
  const numberEditRef = useRef<HTMLInputElement>(null);
  const [number, setNumber] = useState(props.fe.number);

  const width = 200;
  const height = 120;
  const titleHeight = 20;
  const recipe = useAppSelector((state: RootState) => state.factory.recipes.find((r: Recipe) => r.id === props.fe.recipeId));

  function startNumberEdit() {
    if(numberEditRef && numberEditRef.current) {
      numberEditRef.current.hidden = false;
      numberEditRef.current.focus();
    }
  }

  function stopNumberEdit() {
    if(numberEditRef && numberEditRef.current) {
      numberEditRef.current.hidden = true;
    }
  }

  function onNumberKey(e: any) {
    if (e.key === "Enter") {
      stopNumberEdit();
    }
  }

  function onNumberInput(e: any) {
    if (Number(e.target.value) || e.target.value === "") {
      setNumber(e.target.value);
      if(Number(e.target.value)) {
        dispatch(syncWithServer(setElementNumber({id: props.fe.id, number: Number(e.target.value)}), props.firestore));
      }
    }
  }

  const onTitleMouseDown = (e: any) => {
    if(e.buttons === 1) {
      props.setDragged(props.fe.id);
    }
  };

  const onMouseUp = (e: any) => {
    if (props.isGrabbed) {
      props.setDragged(null);
      dispatch(syncWithServer(setElementPosition({
        id: props.fe.id, 
        pos: {
          x: props.fe.position.x + props.positionOffset.x,
          y: props.fe.position.y + props.positionOffset.y
      }}), props.firestore));
    }
  };

  function onContextMenu(e: any) {
    props.contextMenu({
      x: e.pageX, 
      y: e.pageY, 
      operations: [{name: "Delete", display: "Delete element", 
        onclick: () => {
          dispatch(syncWithServer(deleteElement(props.fe.id), props.firestore));
        }}], 
      isVisible: true})
  }
  
  if(recipe) {
    const inputsDraw = recipe.ingredients.map((ia: ItemAmount, i: number) => {
      const socket: FactoryElementSocket = {
        id: props.fe.id+"#I"+i+"_"+ia.item,
        number: i,
        velocity: {
          item: ia.item,
          amount: (ia.amount*props.fe.number*(60/recipe.executionTimeInSec))
        },
        type: "input",
        owner: props.fe
      };

      return (<FactoryElementSocketDraw
        key={socket.id}
        setDragged={props.setDragged}
        setMouseOver={props.setMouseOver}
        socket={socket}
        firestore={props.firestore}
      />);
    });
    const outputsDraw = recipe.products.map((ia: ItemAmount, i: number) => {
      const socket: FactoryElementSocket = {
        id: props.fe.id+"#O"+i+"_"+ia.item,
        number: i,
        velocity: {
          item: ia.item,
          amount: (ia.amount*props.fe.number*(60/recipe.executionTimeInSec))
        },
        type: "output",
        owner: props.fe
      };

      return (<FactoryElementSocketDraw
        key={socket.id}
        setDragged={props.setDragged}
        setMouseOver={props.setMouseOver}
        socket={socket}
        firestore={props.firestore}
      />);
    });

    return (
      <svg
        x={props.fe.position.x + props.positionOffset.x}
        y={props.fe.position.y + props.positionOffset.y}
        width={width}
        height={height}
        onContextMenu={onContextMenu}
      >
        <rect
          className="FactoryElement-border"
          width={width}
          height={height}
          rx={5}
          onMouseUp={onMouseUp}
        />
        <rect
          className="FactoryElement"
          width={width-2}
          height={height-2}
          rx={4}
          x={1}
          y={1}
          onMouseUp={onMouseUp}
        />
        <rect
          className="FactoryElement-title"
          width={width-4}
          x={2}
          y={2}
          height={titleHeight}
          rx={3}
          onMouseDown={onTitleMouseDown}
          onMouseUp={onMouseUp}
        />
        <rect
          className="FactoryElement-title"
          width={width-4}
          x={2}
          y={6}
          height={titleHeight-3}
          onMouseDown={onTitleMouseDown}
          onMouseUp={onMouseUp}
        />
        <text
          x={4}
          y={2 + 16}
          className={classNames("FactoryElement-title-text", "noselect")}
          onMouseDown={onTitleMouseDown}
          onMouseUp={onMouseUp}
        >
          {recipe.name}
        </text>
        <svg
          className="FactoryElement-inputs"
          x={0}
          y={titleHeight + 6}
          width={30}
          height={height-titleHeight-6}
        >
          {inputsDraw}
        </svg>
        <svg
          className="FactoryElement-outputs"
          x={170}
          y={titleHeight + 6}
          width={30}
          height={height-titleHeight-6}
        >
          {outputsDraw}
        </svg>
        <SvgAtlasImage
          y={titleHeight + 2}
          x={width/2 - 40}
          name={recipe.executor}
          size={80}
          className="FactoryElement-executor-icon"
        />
        <svg
          x={width/2 - 30 - 2}
          y={titleHeight + 2 + 80}
          width={30}
          height={15}
          onClick={startNumberEdit}
          data-tip={JSON.stringify({title: "Number of elements", content: "Click to edit" })}
          data-for="simpleTooltip"
        >
          <rect
            className="FactoryElement-number-rect"
            width={30}
            height={15}
            rx={3}
          />
          <text
            className={classNames("FactoryElement-number-text","noselect")}
            x={15}
            y={12}
            width={30}
          >
            x{props.fe.number}
          </text>
          <foreignObject width={30} height={15} x={0} y={0} className="FactoryElement-number-foreign">
            <input ref={numberEditRef} type="text"
              className="FactoryElement-number-input"
              hidden
              onBlur={stopNumberEdit}
              onKeyUp={onNumberKey}
              onInput={onNumberInput}
              value={number}
            />
          </foreignObject>
        </svg>
        <svg
          x={width/2 + 2}
          y={titleHeight + 2 + 80}
          width={30}
          height={15}
        >
          <rect
            className="FactoryElement-efficiency-rect"
            width={30}
            height={15}
            rx={3}
            data-tip={JSON.stringify({title: "Production efficiency", content: "Calculated based on production capacity, inputs and outputs" })}
            data-for="simpleTooltip"
          />
          <text
            className={classNames("FactoryElement-efficiency-text","noselect",getEfficiencyClass(props.fe.efficiency))}
            x={15}
            y={12}
            width={30}
          >
            {(props.fe.efficiency*100).toFixed()}%
          </text>
        </svg>
      </svg>
    );
  } else
    return <></>;
}