// external:
import React, { useCallback, useState } from "react";
import classNames from "classnames";
// internal:
import { Recipe } from "../../../../../Model/Recipe";
import { Item, ItemAmount } from "../../../../../Model/Item";
import { RootState } from "../../../../../Redux/store";
import { useAppSelector } from "../../../../../Redux/hooks";
import CardsBrowser from "../../../../../Components/CardsBrowser/CardsBrowser";
import MultiSelectInput from "../../../../../Components/MultiSelectInput/MultiSelectInput";
import { Building } from "../../../../../Model/Building";
import RecipeCard from "../../../../../Components/Cards/RecipeCard/RecipeCard";
// styling: 
import './AssetBrowser.scss';

function Recipes(){
  const [searchString, setSearchString] = useState(RegExp(""));
  const executors = useAppSelector((state: RootState) => state.factory.buildings);
  const items = useAppSelector((state: RootState) => state.factory.items);

  const [executorsFilter, setExecutorsFilter] = useState<string[]>([]);
  const [ingredientsFilter, setIngredientsFilter] = useState<string[]>([]);
  const [productsFilter, setProductsFilter] = useState<string[]>([]);
  const [showAltRecipes, setShowAltRecipes] = useState<boolean>(true);
  const [showFicsmasRecipes, setShowFicsmasRecipes] = useState<boolean>(true);

  const filterFunction = useCallback((r: Recipe): boolean => {
    return (r.name.match(searchString) !== null) &&
      ((executorsFilter.length === 0) || executorsFilter.includes(r.executor)) &&
      ((ingredientsFilter.length === 0) || (r.ingredients.map((ia: ItemAmount) => ingredientsFilter.includes(ia.item)).includes(true))) &&
      ((productsFilter.length === 0) || (r.products.map((ia: ItemAmount) => productsFilter.includes(ia.item)).includes(true))) &&
      ((!r.event) || (r.event === "FICSMAS" && showFicsmasRecipes)) &&
      ((!r.isAlt) || (r.isAlt && showAltRecipes))
  },
  [searchString, executorsFilter, ingredientsFilter, productsFilter, showAltRecipes, showFicsmasRecipes]);

  const filteredRecipes = useAppSelector((state: RootState) => 
    state.factory.recipes.filter((r: Recipe) => filterFunction(r)));

  const executorItems = executors.map((e: Building) => {return { id: e.name, display: e.name}});
  const itemsItems = items.map((i: Item) => {return { id: i.name, display: i.name}});

  const recipesDraw = filteredRecipes.map(
    (i: Recipe) => <RecipeCard key={i.id} recipe={i} draggable/>
  );

  const onInput = (e: any) => {
    setSearchString(RegExp(e.target.value, "i"));
  };

  const onExecutorFilterChange = (executors: string[]) => {
    setExecutorsFilter(executors);
  };

  const onIngredientsFilterChange = (executors: string[]) => {
    setIngredientsFilter(executors);
  };

  const onProductsFilterChange = (executors: string[]) => {
    setProductsFilter(executors);
  };

  const toggleAltRecipes = () => {
    setShowAltRecipes((previous: boolean) => !previous);
  };

  const toggleFicsmasRecipes = () => {
    setShowFicsmasRecipes((previous: boolean) => !previous);
  };

  const onDragStart = (e: any) => {
    e.dataTransfer.setData('factory/recipe', e.target.dataset.value);
    e.dataTransfer.effectAllowed = 'move';
  }

  return (
    <React.Fragment>
      <div className="AssetBrowser-filterBar">
        <input className={classNames("input", "is-small")} type="text"
          placeholder={classNames("Recipe", "name")}
          onInput={onInput}
        />
        <MultiSelectInput items={executorItems} onSelectionChange={onExecutorFilterChange} description="Produced in"/>
        <MultiSelectInput items={itemsItems} onSelectionChange={onIngredientsFilterChange} description="Ingredients"/>
        <MultiSelectInput items={itemsItems} onSelectionChange={onProductsFilterChange} description="Products"/>
        <div className="AssetBrowser-filterBar-filter" onClick={toggleAltRecipes}>
          <input type="checkbox" checked={showAltRecipes}/> ALT Recipes
        </div>
        <div className="AssetBrowser-filterBar-filter" onClick={toggleFicsmasRecipes}>
          <input type="checkbox" checked={showFicsmasRecipes}/> FICSMAS Recipes
        </div>
      </div>
      <div className="AssetBrowser-assets" onDragStart={onDragStart}>
        <CardsBrowser>
          {recipesDraw}
        </CardsBrowser>
      </div>
    </React.Fragment>
  );
}

export default Recipes;