// external:
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// internal:
import type { RootState } from './store';

interface ConnectionsSettings {
  isThroughputVisible: boolean;
}

interface AssetBrowserSettings {
  isPerMinuteModeEnabled: boolean;
}

interface EditorSettings {
  connections: ConnectionsSettings;
  assetBrowser: AssetBrowserSettings;
}

interface SettingsState {
  editor: EditorSettings;
}

const initialState: SettingsState = {
  editor: {
    connections: {
      isThroughputVisible: true,
    },
    assetBrowser: {
      isPerMinuteModeEnabled: true,
    }
  }
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setEditorConnectionsSettings: (state, action: PayloadAction<ConnectionsSettings>) => {
      state.editor.connections = action.payload;
    },
    setEditorAssetBrowserSettings: (state, action: PayloadAction<AssetBrowserSettings>) => {
      state.editor.assetBrowser = action.payload;
    }
  }
});

export const { setEditorConnectionsSettings, setEditorAssetBrowserSettings } = settingsSlice.actions;

export const selectSettings = (state: RootState) => state.settings;

export default settingsSlice.reducer;