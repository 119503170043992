import { useState } from "react";
import { useAppSelector } from "../../Redux/hooks";
import { RootState } from "../../Redux/store";
import { SectionConfig } from "../../Components/EditorUI/EditorComponentFactory/EditorComponentFactory";
import EditorUI from "../../Components/EditorUI/EditorUI";
import { SatisfactoryMilestone, SatisfactoryTier } from "../../Redux/FactorySlice";
import SatisfactoryMilestoneDetailsDraw from "./Components/SatisfactoryMilestoneDetailsDraw/SatisfactoryMilestoneDetailsDraw";
import SatisfactoryTierDraw from "./Components/SatisfactoryTierDraw/SatisfactoryTierDraw";

export default function TechTreePage() {
  const tiers = useAppSelector((state: RootState) => state.factory.techtree.tiers);
  const [selectedTier, setSelectedTier] = useState<SatisfactoryTier>(tiers?.[0]);
  const [selectedMilestone, setSelectedMilestone] = useState<SatisfactoryMilestone>(tiers?.[0]?.milestones?.[0]);

  function selectTierAndMilestone(t: SatisfactoryTier, m: SatisfactoryMilestone) {
    setSelectedTier(t);
    setSelectedMilestone(m);
  }

  const tiersDraw = tiers.map((st: SatisfactoryTier) => 
    <SatisfactoryTierDraw tier={st} key={st.id} selectedTier={selectedTier} selectedMilestone={selectedMilestone} selectTierAndMilestone={selectTierAndMilestone}/>
  );
  const primitives = [
    {
      id: "techtree", 
      name: "Tech Tree",
      element: <div>{tiersDraw}</div>
    },
    {
      id: "milestone", 
      name: "Milestone View",
      element: <SatisfactoryMilestoneDetailsDraw tier={selectedTier} milestone={selectedMilestone}/>
    }
  ];

  const [sections] = useState<SectionConfig[]>([
    {
      id: "root",
      type: "horizontal",
      elements: [
        {
          id: "techtree",
          weight: 0.7
        },
        {
          id: "milestone",
          weight: 0.3,
          maxSize: "50%"
        }
      ]
    }
  ]);

  return (<EditorUI primitives={primitives} initialSections={sections}/>);
}