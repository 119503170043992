// internal:
import { useAppSelector } from "../../../Redux/hooks";
import { RootState } from "../../../Redux/store";
import { Recipe } from "../../../Model/Recipe";
import { Item, ItemAmount } from "../../../Model/Item";
import RecipeCard from "../../../Components/Cards/RecipeCard/RecipeCard";
import CardsBrowser from "../../../Components/CardsBrowser/CardsBrowser";
import AtlasImage from "../../../Components/ItemImage/ItemImage";
import PropsTable from "../../../Components/PropsTable/PropsTable";
import TitleRow from "../../../Components/PropsTable/TitleRow/TitleRow";
import PropRow from "../../../Components/PropsTable/PropRow/PropRow";
// styling:
import './SingleItemPage.scss';

function SingleItemPage(props: {
  item?: string;
}) {
  const item = useAppSelector((state: RootState) => state.factory.items.find((i: Item) => i.id === props.item));
  const recipes = useAppSelector((state: RootState) => state.factory.recipes.filter((r: Recipe) => r.products.find((ia: ItemAmount) => item ? ia.item === item.name : false) !== undefined));

  if(item) {
    const recipesDraw = recipes.map((r: Recipe) => <RecipeCard key={r.id} recipe={r}/>)

    return (
      <div className="SingleItemPage">
        <div className="SingleItemPage-data">
          <div className="SingleItemPage-image">
            <AtlasImage
              name={item.name}
              size={200}
            />
          </div>
          <PropsTable className="SingleItemPage-table">
            <TitleRow>{item.name}</TitleRow>
            <PropRow name="Type" value={item.category}/>
          </PropsTable>
        </div>
        <div className="SingleItemPage-recipes">
        Recipes:
          <CardsBrowser>
            {recipesDraw}
          </CardsBrowser>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        Non-existent item.
      </div>
    );
  }
  
}

export default SingleItemPage;