// internal:
import Recipes from './Recipes';
// styling:
import './AssetBrowser.scss';

function AssetBrowser() {
  return (
    <div className="AssetBrowser">
      <Recipes/>
    </div>
  );
}

export default AssetBrowser;