// external:
import { useEffect, useState } from "react";
import classNames from "classnames";
// styling:
import "./ContextMenu.scss";

export interface ContextMenuOperation {
  name: any;
  display: any;
  onclick: (position: {x: number, y: number}) => void;
}

export function clearContextMenuData(): ContextMenuData {
  return { x: 0, y: 0, operations: [], isVisible: false };
}

export interface ContextMenuData {
  x: number;
  y: number;
  operations: ContextMenuOperation[];
  isVisible: boolean;
  onHide?: () => void;
}

function OperationComponent(props: {
  operation: ContextMenuOperation,
  position: {x: number, y: number},
  hideMenu: () => void;
}) {
  const onClick = () => {
    props.hideMenu();
    props.operation.onclick(props.position);
  };

  return (
    <li key={props.operation.name} onClick={onClick}>{props.operation.display}</li>
  );
}

function ContextMenu(props: {
  context: ContextMenuData;
}) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(props.context.isVisible)
  }, [props.context.isVisible])

  function hideMenu() {
    console.log("xxx");
    props.context.isVisible = false;
    setIsVisible(false);
    props.context.onHide?.();
  }

  if (isVisible && props.context.operations) {
    const operationsDraw =
      props.context.operations.map((op: ContextMenuOperation) => {
        return (
          <OperationComponent key={op.name} operation={op} position={{ x: props.context.x, y: props.context.y }} hideMenu={hideMenu} />
        );
      });
    return (
      <div className={classNames("ContextMenu", "noselect")} style={{ top: props.context.y, left: props.context.x }}>
        <ul className="ContextMenu-list">
          {operationsDraw}
        </ul>
      </div>
    );
  } else {
    return <></>;
  }
}

export default ContextMenu;