// external:
import { Firestore } from "firebase/firestore";
import classNames from "classnames";
// internal:
import { useAppDispatch, useAppSelector } from "../../../../../../Redux/hooks";
import { RootState } from "../../../../../../Redux/store";
import { deleteConnection, syncWithServer } from "../../../../../../Redux/FactorySlice";
import { FactoryConnection } from "../../../../../../Model/FactoryConnection";
import FactoryElement from "../../../../../../Model/FactoryElement";
import { Item } from "../../../../../../Model/Item";
import TextInRect from "../TextInRect/TextInRect";
import { ContextMenuData } from "../../../../../../Components/ContextMenu/ContextMenu";
import LinkPath from "../LinkPath/LinkPath";
import { calcOutputX, calcConnectionY, calcInputX} from "../Helpers";
//styling:
import './Connection.scss';

export default function Connection(props: {
  fc: FactoryConnection,
  endOffset?: {x: number, y: number},
  startOffset?: {x: number, y: number},
  firestore: Firestore;
  contextMenu: (context: ContextMenuData) => void;
}) {
  const dispatch = useAppDispatch();
  const startEl = useAppSelector((state: RootState) => 
    state.factory.elements.find((e: FactoryElement) => e.id === props.fc.start.element)
  );
  const endEl = useAppSelector((state: RootState) => 
    state.factory.elements.find((e: FactoryElement) => e.id === props.fc.end.element)
  );
  const content = useAppSelector((state: RootState) => props.fc.getContent(state.factory));
  const item = useAppSelector((state: RootState) => state.factory.items.find((i: Item) => i.name === content.item));
  const isThorughputVisible = useAppSelector((state: RootState) => state.settings.editor.connections.isThroughputVisible);

  function onContextMenu(e: any) {
    props.contextMenu({
      x: e.pageX, 
      y: e.pageY, 
      operations: [{name: "Delete", display: "Delete connection", 
        onclick: () => {
          dispatch(syncWithServer(deleteConnection(props.fc.id), props.firestore));
        }}], 
      isVisible: true})
  }

  if (startEl && endEl) {
    let conStart = {
      x: calcOutputX(startEl.position, props.fc.start.socket),
      y: calcConnectionY(startEl.position, props.fc.start.socket)
    };

    let conEnd = {
      x: calcInputX(endEl.position, props.fc.end.socket),
      y: calcConnectionY(endEl.position, props.fc.end.socket)
    };

    if (props.endOffset)
      conEnd = {x: conEnd.x + props.endOffset.x, y: conEnd.y + props.endOffset.y};
    if (props.startOffset)
      conStart = {x: conStart.x + props.startOffset.x, y: conStart.y + props.startOffset.y};

    let middle = {
      x: (conStart.x + conEnd.x)/2,
      y: (conStart.y + conEnd.y)/2,
    };

    return (
      <g className={classNames("Connection", item?.state, "noselect")}
      >
        <LinkPath startPos={conStart} endPos={conEnd}/>
        {isThorughputVisible ? <g onContextMenu={onContextMenu}>
          <TextInRect text={String(content.amount)+"/m"} position={middle}
            dataTip={JSON.stringify(content)}
            dataFor={item?.state === "solid" ? "conveyorTooltip" : "pipelineTooltip"}
          />
        </g> : <></>}
      </g>
    );
  } else {
    return <></>;
  }
}