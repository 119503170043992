// external:
import classNames from "classnames";
import { useRef } from "react";
// internal:
import useOnScreen from "../../Hooks/UseOnScreen/useOnScreen";
// styling:
import './Card.scss';

export default function Card(props: {
  children?: any;
  className?: string;
  draggable?: boolean;
  dataValue?: string;
  onClick?: () => void;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref)

  return (
    <div className={classNames("Card", (props.draggable ? "draggable" : ""), props.className)} draggable={props.draggable} data-value={props.dataValue} ref={ref} onClick={props.onClick}>
      {isVisible ? props.children : <></>}
    </div>
  );
}