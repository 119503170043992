import Converter from "../Converter";
import SaveFormatV1, { FactoryConnectionParams } from "../Versions/V1";
import SaveFormatV2 from "../Versions/V2";

export default class V1toV2 extends Converter {
  inputVersion: number = 1;
  outputVersion: number = 2;

  convertSave(save: SaveFormatV1): SaveFormatV2 {
    let newSave: SaveFormatV2 = {
      name: save.name,
      version: save.version,
      template: save.template,
      factory: JSON.parse(save.factory)
    };

    newSave.factory.connections.forEach((fc: FactoryConnectionParams, index: number) => {
      if (!fc.id) {
        fc.id = "c" + index;
      }
    });

    return newSave;
  }
}