import { FactoryState } from "../../Redux/FactorySlice";
import { FactoryConnection } from "../FactoryConnection";
import FactoryElement from "../FactoryElement";
import { CurrentFormat, currentVersion } from "./Current";

export default function generateCurrentSavefile(factory: FactoryState): CurrentFormat {
  return {
    name: factory.name,
    template: "Satisfactory",
    version: currentVersion,
    factory: {
      connections: factory.connections.map((c: FactoryConnection) => c.serialize()),
      elements: factory.elements.map((e: FactoryElement) => e.serialize())
    }
  };
}