import SaveFormat from "../SaveFormat";
import { FactoryConnectionParams, FactoryElementSocket, Position } from "./V1";

export interface FactoryElementParams2 {
  position: Position;
  number: number;
  id: string;
  recipeId: string; // type field changed to recipeId
  inputSockets: FactoryElementSocket[];
  outputSockets: FactoryElementSocket[];
  efficiency: number;
}

export interface FactoryField {
  connections: FactoryConnectionParams[];
  elements: FactoryElementParams2[];
}

export const version = 3;

export default interface SaveFormatV3 extends SaveFormat {
  factory: FactoryField;
  template: string;
}