import { useContext } from "react";
import { EditorContext } from "../EditorContext/EditorContext";
import EditorSection from "../Section/EditorSection";

export interface ElementConfig {
  id: string;
  weight?: number;
  minSize?: string;
  maxSize?: string;
};

type SectionType = "vertical" | "horizontal" | "cards";

export interface SectionConfig {
  id: string;
  type: SectionType;
  elements: ElementConfig[];
};

export default function EditorComponentFactory(props: {
  id: string;
  sections: SectionConfig[];
}) {
  const editorContext = useContext(EditorContext);

  const found = editorContext.primitives().find((value: {id: string}) => value.id === props.id);
  if (found !== undefined) {
    return found.element;
  }

  const foundSection1 = props.sections?.find((value: {id: string}) => value.id === props.id);
  if (foundSection1 !== undefined) {
    return <EditorSection config={foundSection1} sections={props.sections}/>
  }

  const foundSection = editorContext.sections().find((value: {id: string}) => value.id === props.id)
  if (foundSection !== undefined) {
    return <EditorSection config={foundSection} sections={props.sections}/>
  }
  return <></>;
}