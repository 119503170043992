// external:
import { Link } from 'react-router-dom';
// styling:
import './NavBar.scss';

function NavBar(props: {
  children?: any;
}) {
  return (
    <div className="NavBar">
      <div className="NavBar-content">
        <div className="NavBar-brand">
          <Link to="/">
            <img src="Logo.svg" className="NavBar-logo" alt="Cogler logo"/>Cogler
          </Link>
        </div>
        {props.children}
      </div>
      <div className="NavBar-placeholder"/>
    </div>
  );
}

export default NavBar;