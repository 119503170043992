import React from "react";
import ReactTooltip from "react-tooltip";
// styling:
import './ConveyorTooltip.scss';

export default function ConveyorTooltip() {
  return (
    <ReactTooltip id='conveyorTooltip' effect='solid' place='bottom'
      getContent={(dataTip: string) => {
        if(dataTip === null)
          return;
        try {
          const parsed = JSON.parse(dataTip);
          const conveyors = [
            {tier: "Mk.1", tput: 60 },
            {tier: "Mk.2", tput: 120 },
            {tier: "Mk.3", tput: 270 },
            {tier: "Mk.4", tput: 480 },
            {tier: "Mk.5", tput: 780 },
          ];

          if(parsed.hasOwnProperty('item') && parsed.hasOwnProperty('amount')) {
            let isAlreadyMaxed = false;
            const conveyorsDraw = conveyors.map((v: {tier: string, tput: number}) => {

              if (!isAlreadyMaxed) {
                if (Math.ceil(parsed.amount / v.tput) <= 1)
                  isAlreadyMaxed = true;

                return (
                  <React.Fragment key={v.tier}>
                    <div key="tier" className="ConveyorTooltip-conveyors-tier">
                      {v.tier + (isAlreadyMaxed ? "+": "")}
                    </div>
                    <div key="number" className="ConveyorTooltip-conveyors-number">
                      {Math.ceil(parsed.amount / v.tput)}
                    </div>
                  </React.Fragment>
                );
              } else {
                return <></>;
              }
            });

            return (
              <div className="ConveyorTooltip">
                Conveyors needed:
                <div className="ConveyorTooltip-conveyors">
                  {conveyorsDraw}
                </div>
              </div>
            );
          }
        } catch (error) {
          return(<div>Conveyor tooltip: {dataTip}</div>);
        }
        
      }}
    />
  );
}