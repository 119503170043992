// internal:
import { FactoryTemplate } from "../Redux/FactorySlice";

export function GetSatisfactoryTemplate(): FactoryTemplate {
  const buildingsData = require('./Satisfactory-Buildings.json');
  const itemsData = require('./Satisfactory-Items.json');
  const recipesData = require('./Satisfactory-Recipes.json');
  const techtreeData = require('./Satisfactory-Techtree.json');

  return {
    name: "Satisfactory",
    buildings: buildingsData.buildings,
    items: itemsData.items,
    recipes: recipesData.recipes,
    techtree: techtreeData
  }
}