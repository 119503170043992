// external:
import { useParams } from "react-router-dom";
// internal:
import { useAppSelector } from "../../../Redux/hooks";
import { RootState } from "../../../Redux/store";
import { Building } from "../../../Model/Building";
import { Recipe } from "../../../Model/Recipe";
import RecipeCard from "../../../Components/Cards/RecipeCard/RecipeCard";
import CardsBrowser from "../../../Components/CardsBrowser/CardsBrowser";
import { ItemAmount } from "../../../Model/Item";
import AtlasImage from "../../../Components/ItemImage/ItemImage";
import ItemCost from "../../../Components/ItemCost/ItemCost";
import PropRow from "../../../Components/PropsTable/PropRow/PropRow";
// styling:
import './SingleBuildingPage.scss';
import PropsTable from "../../../Components/PropsTable/PropsTable";
import TitleRow from "../../../Components/PropsTable/TitleRow/TitleRow";

function SingleBuildingPage(props: {
  building?: string;
}) {
  const { buildingId: buildingUrlParam } = useParams();
  const buildingId = buildingUrlParam ?? props.building ?? "assembler";
  const building = useAppSelector((state: RootState) => state.factory.buildings.find((i: Building) => i.id === buildingId))
  const recipes = useAppSelector((state: RootState) => state.factory.recipes.filter((r: Recipe) => building ? r.executor === building.name : false));

  if(building) {
    const recipesDraw = recipes.map((r: Recipe) => <RecipeCard key={r.id} recipe={r}/>)

    const ingredients = building.ingredients.map((ia: ItemAmount) => <ItemCost ia={ia}/>);

    return (
      <div className="SingleBuildingPage">
        <div className="SingleBuildingPage-data">
          <div className="SingleBuildingPage-image">
            <AtlasImage
              name={building.name}
              size={200}
            />
          </div>
          <PropsTable className="SingleBuildingPage-table">
            <TitleRow>{building.name}</TitleRow>
            <PropRow name="Type" value={building.typeId}/>
            <PropRow name="Ingredients" value={ingredients}/>
            <PropRow name="Power Consumption" value={building.powerUse.min === building.powerUse.max ? building.powerUse.min+" MW" : building.powerUse.min+"-"+building.powerUse.max+"MW"}/>
            <PropRow name="Conveyor inputs" value={building.conveyorInputs} isHidden={building.conveyorInputs === 0}/>
            <PropRow name="Conveyor outputs" value={building.conveyorOutputs} isHidden={building.conveyorOutputs === 0}/>
            <PropRow name="Pipeline inputs" value={building.pipeInputs} isHidden={building.pipeInputs === 0}/>
            <PropRow name="Pipeline outputs" value={building.pipeOutputs} isHidden={building.pipeOutputs === 0}/>
          </PropsTable>
        </div>
        <div className="SingleBuildingPage-recipes">
        Recipes:
          <CardsBrowser>
            {recipesDraw}
          </CardsBrowser>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        Non-existent building.
      </div>
    );
  }
}

export default SingleBuildingPage;