//styling: 
import './CardsBrowser.scss';

function CardsBrowser(props: {
  children: any
}) {
  return (
    <div className="CardsBrowser">
      {props.children}
    </div>
  );
}

export default CardsBrowser;